import { db } from "../firebase";
export const calculatorService = {
    getCalculationData,
    getallExportServices,
    compareCountryArrays,
    // getColomboExpressAndUpdateBulkAndExpress,
    // mergeShipments
};

async function getCalculationData() {
    // console.log("getCalculationData");
    return await db
        .collection("pages")
        .doc("exportServiceData")
        .get()
        .then(response => {
            const data = response.data()

            return data;
        })
        .catch(error => {
            // console.error("error from firebase getCalculationData", error);
            return error;
        });
}

async function getallExportServices() {
    let allExportServices = [{}, {}, {}, {}, {}, {}, {}, {}, {}]
    return await db
        .collection("exportServices")
        .get()
        .then((querySnapShot) => {
            querySnapShot.forEach((doc) => {
                let data = {};
                data = doc.data()
                data.id = doc.id
                if (data.id == "colomboExpress") allExportServices[0] = data
                if (data.id == "colomboEconomy") allExportServices[1] = data
                if (data.id == "singaporeSimple") allExportServices[2] = data
                if (data.id == "singaporeSmart") allExportServices[3] = data
                // if (data.id == "swissFullyTracked") allExportServices[4] = data
                // if (data.id == "swissCountryTrack") allExportServices[5] = data
                if (data.id == "colomboSpecial") allExportServices[4] = data
                if (data.id == "colomboBulk") allExportServices[5] = data
                if (data.id == "hermies") allExportServices[6] = data
                if (data.id == "usps") allExportServices[7] = data
                if (data.id == "indiaPost") allExportServices[8] = data

            });
            // console.log("allExportServices", allExportServices);
            return allExportServices
        })
        .catch((error) => {
            // console.error("error in allExportServices", error);
            return error
        });
}

// async function getColomboExpressAndUpdateBulkAndExpress() {
//     let colomboexpressdata = null
//     await db.collection("exportServices").doc("colomboExpress").get().then((querySnapshot) => {
//         colomboexpressdata = querySnapshot.data();
//         console.log("colomboexpressdata", colomboexpressdata)
//         // let countries = [
//         //     {
//         //         "zone": 3,
//         //         "code": "AU",
//         //         "name": " australia"
//         //     },
//         //     {
//         //         "code": "BH",
//         //         "zone": 2,
//         //         "name": "bahrain"
//         //     },
//         //     {
//         //         "name": "canada",
//         //         "code": "CA",
//         //         "zone": 1
//         //     },
//         //     {
//         //         "name": "china",
//         //         "code": "CN",
//         //         "zone": 2
//         //     },
//         //     {
//         //         "zone": 3,
//         //         "code": "FR",
//         //         "name": "france"
//         //     },
//         //     {
//         //         "code": "DE",
//         //         "zone": 3,
//         //         "name": "germany"
//         //     },
//         //     {
//         //         "code": "HK",
//         //         "zone": 2,
//         //         "name": "hong kong"
//         //     },
//         //     {
//         //         "zone": 2,
//         //         "name": "india",
//         //         "code": "IN"
//         //     },
//         //     {
//         //         "name": "italy",
//         //         "zone": 3,
//         //         "code": "IT"
//         //     },
//         //     {
//         //         "zone": 1,
//         //         "code": "JP",
//         //         "name": "japan"
//         //     },
//         //     {
//         //         "zone": 1,
//         //         "code": "SK",
//         //         "name": "korea south"
//         //     },
//         //     {
//         //         "code": "KW",
//         //         "zone": 2,
//         //         "name": "kuwait"
//         //     },
//         //     {
//         //         "code": "MY",
//         //         "name": "malaysia",
//         //         "zone": 2
//         //     },
//         //     {
//         //         "name": "maldives",
//         //         "code": "MV",
//         //         "zone": 2
//         //     },
//         //     {
//         //         "name": "netherlands",
//         //         "code": "NL",
//         //         "zone": 3
//         //     },
//         //     {
//         //         "name": "newzealand",
//         //         "code": "NZ",
//         //         "zone": 3
//         //     },
//         //     {
//         //         "name": "oman",
//         //         "zone": 2,
//         //         "code": "OM"
//         //     },
//         //     {
//         //         "name": "qatar",
//         //         "code": "QA",
//         //         "zone": 1
//         //     },
//         //     {
//         //         "name": "saudi arabia",
//         //         "zone": 1,
//         //         "code": "SA"
//         //     },
//         //     {
//         //         "name": "singapore",
//         //         "zone": 2,
//         //         "code": "SG"
//         //     },
//         //     {
//         //         "code": "CH",
//         //         "zone": 3,
//         //         "name": "switzerland"
//         //     },
//         //     {
//         //         "code": "AE",
//         //         "zone": 2,
//         //         "name": "United Arab Emirates (UAE)"
//         //     },
//         //     {
//         //         "name": "United Kingdom (UK)",
//         //         "code": "GB",
//         //         "zone": 1
//         //     },
//         //     {
//         //         "name": "United States of America (USA)",
//         //         "code": "US",
//         //         "zone": 1
//         //     },
//         //     {
//         //         "name": "bhutan",
//         //         "code": "BT",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "brunei",
//         //         "code": "BN",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "cambodia",
//         //         "code": "KH"
//         //     },
//         //     {
//         //         "name": "indonesia",
//         //         "zone": 4,
//         //         "code": "ID"
//         //     },
//         //     {
//         //         "code": "IR",
//         //         "zone": 4,
//         //         "name": "iran"
//         //     },
//         //     {
//         //         "zone": 2,
//         //         "name": "jordan",
//         //         "code": "JO"
//         //     },
//         //     {
//         //         "code": "LA",
//         //         "zone": 4,
//         //         "name": "laos"
//         //     },
//         //     {
//         //         "code": "MO",
//         //         "name": "macau SAR china",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "MM",
//         //         "name": "myanmar"
//         //     },
//         //     {
//         //         "name": "nepal",
//         //         "zone": 4,
//         //         "code": "NP"
//         //     },
//         //     {
//         //         "code": "PH",
//         //         "zone": 4,
//         //         "name": "philippines"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "TW",
//         //         "name": "taiwan"
//         //     },
//         //     {
//         //         "name": "turkey",
//         //         "zone": 4,
//         //         "code": "TR"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "vietnam",
//         //         "code": "VN"
//         //     },
//         //     {
//         //         "name": "belgium",
//         //         "code": "BE",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "bulgaria",
//         //         "code": "BG",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "cyprus",
//         //         "code": "CY"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "czech republic",
//         //         "code": "CZ"
//         //     },
//         //     {
//         //         "name": "denmark",
//         //         "code": "DK",
//         //         "zone": 3
//         //     },
//         //     {
//         //         "code": "FI",
//         //         "zone": 3,
//         //         "name": "finland"
//         //     },
//         //     {
//         //         "name": "hungary",
//         //         "zone": 4,
//         //         "code": "HU"
//         //     },
//         //     {
//         //         "name": "latvia",
//         //         "zone": 4,
//         //         "code": "LV"
//         //     },
//         //     {
//         //         "code": "LI",
//         //         "name": "liechtenstein",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "LT",
//         //         "zone": 4,
//         //         "name": "lithuania"
//         //     },
//         //     {
//         //         "name": "luxembourg",
//         //         "zone": 4,
//         //         "code": "LU"
//         //     },
//         //     {
//         //         "name": "malta",
//         //         "zone": 4,
//         //         "code": "MT"
//         //     },
//         //     {
//         //         "code": "MX",
//         //         "name": "mexico",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "MC",
//         //         "name": "monaco",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "PL",
//         //         "zone": 3,
//         //         "name": "poland"
//         //     },
//         //     {
//         //         "zone": 3,
//         //         "name": "portugal",
//         //         "code": "PT"
//         //     },
//         //     {
//         //         "name": "romania",
//         //         "zone": 4,
//         //         "code": "RO"
//         //     },
//         //     {
//         //         "name": "slovakia",
//         //         "code": "SK",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "slovenia",
//         //         "zone": 4,
//         //         "code": "SI"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "spain",
//         //         "code": "ES"
//         //     },
//         //     {
//         //         "name": "sweden",
//         //         "code": "SE",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "VA",
//         //         "name": "vatican city"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "east timor",
//         //         "code": "TS"
//         //     },
//         //     {
//         //         "name": "fiji",
//         //         "zone": 4,
//         //         "code": "FJ"
//         //     },
//         //     {
//         //         "code": "MK",
//         //         "name": "macedonia",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "NT",
//         //         "zone": 4,
//         //         "name": "netherland antilles"
//         //     },
//         //     {
//         //         "code": "RU",
//         //         "name": "russia",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "rwanda",
//         //         "zone": 4,
//         //         "code": "RW"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "saipan",
//         //         "code": "SP"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "RS",
//         //         "name": "serbia"
//         //     },
//         //     {
//         //         "name": "sierra leone",
//         //         "zone": 4,
//         //         "code": "SL"
//         //     },
//         //     {
//         //         "name": "somaliland",
//         //         "code": "XS",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 1,
//         //         "code": "ZA",
//         //         "name": "south africa"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "VC",
//         //         "name": "st. vincent"
//         //     },
//         //     {
//         //         "name": "south sudan",
//         //         "code": "SS",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "SZ",
//         //         "name": "swaziland"
//         //     },
//         //     {
//         //         "name": "yugoslovia",
//         //         "zone": 4,
//         //         "code": "YG"
//         //     },
//         //     {
//         //         "name": "zaire",
//         //         "code": "ZR",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "bangladesh",
//         //         "code": "BD"
//         //     },
//         //     {
//         //         "name": "ireland",
//         //         "zone": 4,
//         //         "code": "IE"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "pakistan",
//         //         "code": "PK"
//         //     },
//         //     {
//         //         "zone": 2,
//         //         "name": "thailand",
//         //         "code": "TH"
//         //     },
//         //     {
//         //         "name": "Afghanistan ",
//         //         "code": "AF",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 1,
//         //         "code": "AL",
//         //         "name": "Albania "
//         //     },
//         //     {
//         //         "code": "DZ",
//         //         "zone": 4,
//         //         "name": "Algeria"
//         //     },
//         //     {
//         //         "code": "AS",
//         //         "name": "American Samoa",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Andorra ",
//         //         "zone": 4,
//         //         "code": "AD"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Angola ",
//         //         "code": "AO"
//         //     },
//         //     {
//         //         "code": "AI",
//         //         "zone": 4,
//         //         "name": "Anguilla"
//         //     },
//         //     {
//         //         "name": "Antigua ",
//         //         "code": "AG",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "AR",
//         //         "name": "Argentina "
//         //     },
//         //     {
//         //         "name": "Armenia",
//         //         "code": "AM",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "AT",
//         //         "name": "Austria"
//         //     },
//         //     {
//         //         "code": "AZ",
//         //         "name": "Azerbaijan ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Bahamas ",
//         //         "code": "BS",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Barbados ",
//         //         "zone": 4,
//         //         "code": "BB"
//         //     },
//         //     {
//         //         "code": "BY",
//         //         "zone": 4,
//         //         "name": "Belarus "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "BZ",
//         //         "name": "Belize"
//         //     },
//         //     {
//         //         "code": "BJ",
//         //         "zone": 4,
//         //         "name": "Benin"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "BM",
//         //         "name": "Bermuda "
//         //     },
//         //     {
//         //         "name": "Bolivia",
//         //         "code": "BO",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "XB",
//         //         "name": "Bonaire",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "BA",
//         //         "zone": 4,
//         //         "name": "Bosnia & Herzegovina"
//         //     },
//         //     {
//         //         "name": "Botswana",
//         //         "code": "BW",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "BR",
//         //         "name": "Brazil",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "BF",
//         //         "zone": 4,
//         //         "name": "Burkina Faso"
//         //     },
//         //     {
//         //         "name": "Burundi ",
//         //         "code": "BI",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Cameroon",
//         //         "code": "CM"
//         //     },
//         //     {
//         //         "code": "IC",
//         //         "name": "Canary Islands, The ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Cape Verde",
//         //         "zone": 4,
//         //         "code": "CV"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Cayman Islands ",
//         //         "code": "KY"
//         //     },
//         //     {
//         //         "code": "CF",
//         //         "zone": 4,
//         //         "name": "Central African Rep"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "TD",
//         //         "name": "Chad"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "CL",
//         //         "name": "Chile"
//         //     },
//         //     {
//         //         "code": "CO",
//         //         "zone": 4,
//         //         "name": "Colombia"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "KM",
//         //         "name": "Comoros "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Congo ",
//         //         "code": "CG"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "CD",
//         //         "name": "Congo, DPR "
//         //     },
//         //     {
//         //         "code": "CK",
//         //         "name": "Cook Islands",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Costa Rica ",
//         //         "code": "CR",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "CI",
//         //         "name": "Cote D Ivoire",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Croatia",
//         //         "code": "HR",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "CU",
//         //         "name": "Cuba ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "XC",
//         //         "zone": 4,
//         //         "name": "Curacao "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Djibouti ",
//         //         "code": "DJ"
//         //     },
//         //     {
//         //         "name": "Dominica ",
//         //         "code": "DM",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "DO",
//         //         "name": "Dominican Rep. ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Ecuador",
//         //         "code": "EC",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Egypt",
//         //         "code": "EG"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "SV",
//         //         "name": "El Salvador "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "ER",
//         //         "name": "Eritrea "
//         //     },
//         //     {
//         //         "name": "Estonia ",
//         //         "zone": 4,
//         //         "code": "EE"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "SZ",
//         //         "name": "Eswatini "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "ET",
//         //         "name": "Ethiopia "
//         //     },
//         //     {
//         //         "name": "Falkland Islands ",
//         //         "code": "FK",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "FO",
//         //         "name": "Faroe Islands "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "GF",
//         //         "name": "French Guyana "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "GA",
//         //         "name": "Gabon "
//         //     },
//         //     {
//         //         "name": "Gambia ",
//         //         "zone": 4,
//         //         "code": "GM"
//         //     },
//         //     {
//         //         "name": "Georgia ",
//         //         "code": "GE",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "GH",
//         //         "name": "Ghana ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Gibraltar",
//         //         "code": "GI"
//         //     },
//         //     {
//         //         "code": "GR",
//         //         "name": "Greece ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "GL",
//         //         "name": "Greenland"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Grenada",
//         //         "code": "GD"
//         //     },
//         //     {
//         //         "code": "GP",
//         //         "zone": 4,
//         //         "name": "Guadeloupe "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "GU",
//         //         "name": "Guam "
//         //     },
//         //     {
//         //         "name": "Guatemala",
//         //         "zone": 4,
//         //         "code": "GT"
//         //     },
//         //     {
//         //         "code": "GG",
//         //         "zone": 4,
//         //         "name": "Guernsey"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "GN",
//         //         "name": "Guinea Rep. "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "GW",
//         //         "name": "Guinea-Bissau"
//         //     },
//         //     {
//         //         "code": "GQ",
//         //         "zone": 4,
//         //         "name": "Guinea-Equatorial "
//         //     },
//         //     {
//         //         "code": "GY",
//         //         "name": "Guyana (British) ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Haiti ",
//         //         "code": "HT"
//         //     },
//         //     {
//         //         "code": "HN",
//         //         "name": "Honduras",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Iceland ",
//         //         "code": "IS",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Iraq",
//         //         "code": "IQ"
//         //     },
//         //     {
//         //         "code": "IL",
//         //         "name": "Israel ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "JM",
//         //         "name": "Jamaica ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Jersey",
//         //         "code": "JE",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "KZ",
//         //         "zone": 4,
//         //         "name": "Kazakhstan"
//         //     },
//         //     {
//         //         "code": "KE",
//         //         "name": "Kenya",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Kiribati",
//         //         "code": "KI"
//         //     },
//         //     {
//         //         "name": "Korea,D.P.R Of ",
//         //         "code": "KR",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Kosovo",
//         //         "code": "KV",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "KG",
//         //         "name": "Kyrgyzstan",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "LB",
//         //         "zone": 4,
//         //         "name": "Lebanon"
//         //     },
//         //     {
//         //         "code": "LS",
//         //         "zone": 4,
//         //         "name": "Lesotho "
//         //     },
//         //     {
//         //         "code": "LR",
//         //         "name": "Liberia ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Libya ",
//         //         "code": "LY",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "MG",
//         //         "zone": 4,
//         //         "name": "Madagascar"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Malawi ",
//         //         "code": "MW"
//         //     },
//         //     {
//         //         "name": "Mali",
//         //         "zone": 4,
//         //         "code": "ML"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "MP",
//         //         "name": "Mariana Islands "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "MH",
//         //         "name": "Marshall Islands"
//         //     },
//         //     {
//         //         "name": "Martinique",
//         //         "zone": 4,
//         //         "code": "MQ"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "MR",
//         //         "name": "Mauritania "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Mauritius",
//         //         "code": "MU"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Mayotte",
//         //         "code": "YT"
//         //     },
//         //     {
//         //         "code": "FM",
//         //         "zone": 4,
//         //         "name": "Micronesia "
//         //     },
//         //     {
//         //         "name": "Moldova, Rep. Of ",
//         //         "code": "MD",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Mongolia ",
//         //         "code": "MN",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "ME",
//         //         "name": "Montenegro, Rep Of "
//         //     },
//         //     {
//         //         "name": "Montserrat ",
//         //         "code": "MS",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "MA",
//         //         "name": "Morocco ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Mozambique ",
//         //         "code": "MZ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Namibia ",
//         //         "zone": 4,
//         //         "code": "NA"
//         //     },
//         //     {
//         //         "name": "Nauru, Rep. Of ",
//         //         "code": "NR",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Nevis ",
//         //         "code": "XN"
//         //     },
//         //     {
//         //         "name": "New Caledonia ",
//         //         "code": "NC",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Nicaragua ",
//         //         "zone": 4,
//         //         "code": "NI"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "NE",
//         //         "name": "Niger "
//         //     },
//         //     {
//         //         "name": "Nigeria ",
//         //         "zone": 4,
//         //         "code": "NG"
//         //     },
//         //     {
//         //         "code": "NU",
//         //         "zone": 4,
//         //         "name": "Niue "
//         //     },
//         //     {
//         //         "name": "North Macedonia ",
//         //         "code": "MK",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Norway ",
//         //         "code": "NO",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Palau ",
//         //         "zone": 4,
//         //         "code": "PW"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Panama ",
//         //         "code": "PA"
//         //     },
//         //     {
//         //         "code": "PG",
//         //         "zone": 4,
//         //         "name": "Papua New Guinea "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Paraguay ",
//         //         "code": "PY"
//         //     },
//         //     {
//         //         "code": "PE",
//         //         "name": "Peru ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "PR",
//         //         "name": "Puerto Rico ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Reunion, Island Of ",
//         //         "code": "RE",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "SH",
//         //         "name": "Saint Helena "
//         //     },
//         //     {
//         //         "name": "Samoa ",
//         //         "zone": 4,
//         //         "code": "WS"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "San Marino ",
//         //         "code": "SM"
//         //     },
//         //     {
//         //         "code": "ST",
//         //         "zone": 4,
//         //         "name": "Sao Tome And Principe "
//         //     },
//         //     {
//         //         "name": "Senegal ",
//         //         "code": "SN",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Seychelles ",
//         //         "code": "SC",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Solomon Islands ",
//         //         "code": "SB",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "SO",
//         //         "zone": 4,
//         //         "name": "Somalia "
//         //     },
//         //     {
//         //         "name": "St. Barthelemy ",
//         //         "zone": 4,
//         //         "code": "XY"
//         //     },
//         //     {
//         //         "name": "St. Eustatius ",
//         //         "code": "XE",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "KN",
//         //         "zone": 4,
//         //         "name": "St. Kitts "
//         //     },
//         //     {
//         //         "code": "LC",
//         //         "name": "St. Lucia ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "XM",
//         //         "zone": 4,
//         //         "name": "St. Maarten "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "SD",
//         //         "name": "Sudan "
//         //     },
//         //     {
//         //         "code": "SR",
//         //         "zone": 4,
//         //         "name": "Suriname "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "SY",
//         //         "name": "Syria "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "PF",
//         //         "name": "Tahiti "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Tajikistan ",
//         //         "code": "TJ"
//         //     },
//         //     {
//         //         "code": "TZ",
//         //         "name": "Tanzania ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Timor-Leste ",
//         //         "zone": 4,
//         //         "code": "TL"
//         //     },
//         //     {
//         //         "name": "Togo TG",
//         //         "zone": 4,
//         //         "code": "TG"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "TO",
//         //         "name": "Tonga "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Trinidad And Tobago ",
//         //         "code": "TT"
//         //     },
//         //     {
//         //         "code": "TN",
//         //         "zone": 4,
//         //         "name": "Tunisia "
//         //     },
//         //     {
//         //         "code": "TM",
//         //         "name": "Turkmenistan ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "TC",
//         //         "name": "Turks & Caicos ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Tuvalu ",
//         //         "code": "TV"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Uganda ",
//         //         "code": "UG"
//         //     },
//         //     {
//         //         "code": "UA",
//         //         "zone": 4,
//         //         "name": "Ukraine "
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "UY",
//         //         "name": "Uruguay "
//         //     },
//         //     {
//         //         "name": "Uzbekistan ",
//         //         "code": "UZ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "name": "Vanuatu ",
//         //         "code": "VU",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "name": "Venezuela ",
//         //         "code": "VE"
//         //     },
//         //     {
//         //         "name": "Virgin Islands-British ",
//         //         "zone": 4,
//         //         "code": "VG"
//         //     },
//         //     {
//         //         "zone": 4,
//         //         "code": "VI",
//         //         "name": "Virgin Islands-US "
//         //     },
//         //     {
//         //         "name": "Yemen, Rep. Of ",
//         //         "zone": 4,
//         //         "code": "YE"
//         //     },
//         //     {
//         //         "code": "ZM",
//         //         "name": "Zambia ",
//         //         "zone": 4
//         //     },
//         //     {
//         //         "code": "ZW",
//         //         "zone": 4,
//         //         "name": "Zimbabwe "
//         //     }
//         // ]
//         // let newcountries = [
//         //     { code: 'CA', name: 'Canada', zone: 1 },
//         //     { code: 'US', name: 'United States of America', zone: 1 },
//         //     { code: 'AD', name: 'Andorra', zone: 1 },
//         //     { code: 'AU', name: 'Australia', zone: 1 },
//         //     { code: 'AT', name: 'Austria', zone: 1 },
//         //     { code: 'BE', name: 'Belgium', zone: 1 },
//         //     { code: 'BG', name: 'Bulgaria', zone: 1 },
//         //     { code: 'HR', name: 'Croatia', zone: 2 },
//         //     { code: 'CY', name: 'Cyprus', zone: 2 },
//         //     { code: 'CZ', name: 'Czech Republic', zone: 2 },
//         //     { code: 'DK', name: 'Denmark', zone: 2 },
//         //     { code: 'EE', name: 'Estonia', zone: 2 },
//         //     { code: 'FI', name: 'Finland', zone: 2 },
//         //     { code: 'FR', name: 'France', zone: 2 },
//         //     { code: 'DE', name: 'Germany', zone: 2 },
//         //     { code: 'GR', name: 'Greece', zone: 2 },
//         //     { code: 'HU', name: 'Hungary', zone: 2 },
//         //     { code: 'IE', name: 'Ireland', zone: 2 },
//         //     { code: 'IT', name: 'Italy', zone: 2 },
//         //     { code: 'JP', name: 'Japan', zone: 2 },
//         //     { code: 'LV', name: 'Latvia', zone: 2 },
//         //     { code: 'LI', name: 'Liechtenstein', zone: 2 },
//         //     { code: 'LT', name: 'Lithuania', zone: 2 },
//         //     { code: 'LU', name: 'Luxembourg', zone: 2 },
//         //     { code: 'MT', name: 'Malta', zone: 2 },
//         //     { code: 'MC', name: 'Monaco', zone: 2 },
//         //     { code: 'NL', name: 'Netherlands', zone: 2 },
//         //     { code: 'NZ', name: 'New Zealand', zone: 2 },
//         //     { code: 'NO', name: 'Norway', zone: 2 },
//         //     { code: 'PL', name: 'Poland', zone: 2 },
//         //     { code: 'PT', name: 'Portugal', zone: 2 },
//         //     { code: 'RO', name: 'Romania', zone: 2 },
//         //     { code: 'SM', name: 'San Marino', zone: 2 },
//         //     { code: 'SK', name: 'Slovakia', zone: 2 },
//         //     { code: 'SI', name: 'Slovenia', zone: 2 },
//         //     { code: 'ES', name: 'Spain', zone: 2 },
//         //     { code: 'SE', name: 'Sweden', zone: 2 },
//         //     { code: 'CH', name: 'Switzerland', zone: 2 },
//         //     { code: 'TR', name: 'Turkey', zone: 2 },
//         //     { code: 'GB', name: 'United Kingdom', zone: 2 },
//         //     { code: 'VA', name: 'Vatican City', zone: 2 },
//         //     { code: 'BH', name: 'Bahrain', zone: 2 },
//         //     { code: 'BD', name: 'Bangladesh', zone: 2 },
//         //     { code: 'BT', name: 'Bhutan', zone: 2 },
//         //     { code: 'BN', name: 'Brunei', zone: 2 },
//         //     { code: 'KH', name: 'Cambodia', zone: 2 },
//         //     { code: 'CN', name: 'China', zone: 2 },
//         //     { code: 'HK', name: 'Hong Kong SAR China', zone: 2 },
//         //     { code: 'IN', name: 'India', zone: 2 },
//         //     { code: 'ID', name: 'Indonesia', zone: 2 },
//         //     { code: 'JO', name: 'Jordan', zone: 2 },
//         //     { code: 'KR', name: 'Korea, Republic of', zone: 2 },
//         //     { code: 'KW', name: 'Kuwait', zone: 2 },
//         //     { code: 'LA', name: 'Laos', zone: 2 },
//         //     { code: 'MO', name: 'Macau SAR China', zone: 2 },
//         //     { code: 'MY', name: 'Malaysia', zone: 2 },
//         //     { code: 'MV', name: 'Maldives', zone: 2 },
//         //     { code: 'MM', name: 'Myanmar', zone: 2 },
//         //     { code: 'NP', name: 'Nepal', zone: 2 },
//         //     { code: 'OM', name: 'Oman', zone: 2 },
//         //     { code: 'PK', name: 'Pakistan', zone: 2 },
//         //     { code: 'PH', name: 'Philippines', zone: 2 },
//         //     { code: 'QA', name: 'Qatar', zone: 2 },
//         //     { code: 'SA', name: 'Saudi Arabia', zone: 2 },
//         //     { code: 'SG', name: 'Singapore', zone: 2 },
//         //     { code: 'TW', name: 'Taiwan', zone: 2 },
//         //     { code: 'TH', name: 'Thailand', zone: 2 },
//         //     { code: 'AE', name: 'United Arab Emirates', zone: 2 },
//         //     { code: 'VN', name: 'Vietnam', zone: 2 },
//         //     { code: 'AF', name: 'Afghanistan', zone: 4 },
//         //     { code: 'AL', name: 'Albania', zone: 4 },
//         //     { code: 'DZ', name: 'Algeria', zone: 4 },
//         //     { code: 'AS', name: 'American Samoa', zone: 4 },
//         //     { code: 'AO', name: 'Angola', zone: 4 },
//         //     { code: 'AI', name: 'Anguilla', zone: 4 },
//         //     { code: 'AG', name: 'Antigua', zone: 4 },
//         //     { code: 'AR', name: 'Argentina', zone: 4 },
//         //     { code: 'AM', name: 'Armenia', zone: 4 },
//         //     { code: 'AW', name: 'Aruba', zone: 4 },
//         //     { code: 'AZ', name: 'Azerbaijan', zone: 4 },
//         //     { code: 'BS', name: 'Bahamas', zone: 4 },
//         //     { code: 'BB', name: 'Barbados', zone: 4 },
//         //     { code: 'BY', name: 'Belarus', zone: 4 },
//         //     { code: 'BZ', name: 'Belize', zone: 4 },
//         //     { code: 'BJ', name: 'Benin', zone: 4 },
//         //     { code: 'BM', name: 'Bermuda', zone: 4 },
//         //     { code: 'BO', name: 'Bolivia', zone: 4 },
//         //     { code: 'XB', name: 'Bonaire', zone: 4 },
//         //     { code: 'BA', name: 'Bosnia & Herzegovina', zone: 4 },
//         //     { code: 'BW', name: 'Botswana', zone: 4 },
//         //     { code: 'BR', name: 'Brazil', zone: 4 },
//         //     { code: 'BF', name: 'Burkina Faso', zone: 4 },
//         //     { code: 'BI', name: 'Burundi', zone: 4 },
//         //     { code: 'CM', name: 'Cameroon', zone: 4 },
//         //     { code: 'IC', name: 'Canary Islands, The', zone: 4 },
//         //     { code: 'CV', name: 'Cape Verde', zone: 4 },
//         //     { code: 'KY', name: 'Cayman Islands', zone: 4 },
//         //     { code: 'CF', name: 'Central African Republic', zone: 4 },
//         //     { code: 'TD', name: 'Chad', zone: 4 },
//         //     { code: 'CL', name: 'Chile', zone: 4 },
//         //     { code: 'CO', name: 'Colombia', zone: 4 },
//         //     { code: 'KM', name: 'Comoros', zone: 4 },
//         //     { code: 'CG', name: 'Congo', zone: 4 },
//         //     { code: 'CD', name: 'Congo, Democratic Republic', zone: 4 },
//         //     { code: 'CK', name: 'Cook Islands', zone: 4 },
//         //     { code: 'CR', name: 'Costa Rica', zone: 4 },
//         //     { code: 'CI', name: 'Cote D Ivoire', zone: 4 },
//         //     { code: 'CU', name: 'Cuba', zone: 4 },
//         //     { code: 'XC', name: 'Curacao', zone: 4 },
//         //     { code: 'DJ', name: 'Djibouti', zone: 4 },
//         //     { code: 'DM', name: 'Dominica', zone: 4 },
//         //     { code: 'DO', name: 'Dominican Republic', zone: 4 },
//         //     { code: 'EC', name: 'Ecuador', zone: 4 },
//         //     { code: 'EG', name: 'Egypt', zone: 4 },
//         //     { code: 'SV', name: 'El Salvador', zone: 4 },
//         //     { code: 'ER', name: 'Eritrea', zone: 4 },
//         //     { code: 'SZ', name: 'Eswatini', zone: 4 },
//         //     { code: 'ET', name: 'Ethiopia', zone: 4 },
//         //     { code: 'FK', name: 'Falkland Islands', zone: 4 },
//         //     { code: 'FO', name: 'Faroe Islands', zone: 4 },
//         //     { code: 'FJ', name: 'Fiji', zone: 4 },
//         //     { code: 'GF', name: 'French Guyana', zone: 4 },
//         //     { code: 'GA', name: 'Gabon', zone: 4 },
//         //     { code: 'GM', name: 'Gambia', zone: 4 },
//         //     { code: 'GE', name: 'Georgia', zone: 4 },
//         //     { code: 'GH', name: 'Ghana', zone: 4 },
//         //     { code: 'GI', name: 'Gibraltar', zone: 4 },
//         //     { code: 'GL', name: 'Greenland', zone: 4 },
//         //     { code: 'GD', name: 'Grenada', zone: 4 },
//         //     { code: 'GP', name: 'Guadeloupe', zone: 4 },
//         //     { code: 'GU', name: 'Guam', zone: 4 },
//         //     { code: 'GT', name: 'Guatemala', zone: 4 },
//         //     { code: 'GG', name: 'Guernsey', zone: 4 },
//         //     { code: 'GN', name: 'Guinea Republic', zone: 4 },
//         //     { code: 'GW', name: 'Guinea-Bissau', zone: 4 },
//         //     { code: 'GQ', name: 'Equatorial Guinea', zone: 4 },
//         //     { code: 'GY', name: 'Guyana (British)', zone: 4 },
//         //     { code: 'HT', name: 'Haiti', zone: 4 },
//         //     { code: 'HN', name: 'Honduras', zone: 4 },
//         //     { code: 'IS', name: 'Iceland', zone: 4 },
//         //     { code: 'IR', name: 'Iran', zone: 4 },
//         //     { code: 'IQ', name: 'Iraq', zone: 4 },
//         //     { code: 'IL', name: 'Israel', zone: 4 },
//         //     { code: 'JM', name: 'Jamaica', zone: 4 },
//         //     { code: 'JE', name: 'Jersey', zone: 4 },
//         //     { code: 'KZ', name: 'Kazakhstan', zone: 4 },
//         //     { code: 'KE', name: 'Kenya', zone: 4 },
//         //     { code: 'KI', name: 'Kiribati', zone: 4 },
//         //     { code: 'KR', name: 'Korea, D.P.R Of', zone: 4 },
//         //     { code: 'KV', name: 'Kosovo', zone: 4 },
//         //     { code: 'KG', name: 'Kyrgyzstan', zone: 4 },
//         //     { code: 'LB', name: 'Lebanon', zone: 4 },
//         //     { code: 'LS', name: 'Lesotho', zone: 4 },
//         //     { code: 'LR', name: 'Liberia', zone: 4 },
//         //     { code: 'LY', name: 'Libya', zone: 4 },
//         //     { code: 'MG', name: 'Madagascar', zone: 4 },
//         //     { code: 'MW', name: 'Malawi', zone: 4 },
//         //     { code: 'ML', name: 'Mali', zone: 4 },
//         //     { code: 'MH', name: 'Marshall Islands', zone: 4 },
//         //     { code: 'MQ', name: 'Martinique', zone: 4 },
//         //     { code: 'MR', name: 'Mauritania', zone: 4 },
//         //     { code: 'MU', name: 'Mauritius', zone: 4 },
//         //     { code: 'YT', name: 'Mayotte', zone: 4 },
//         //     { code: 'FM', name: 'Micronesia', zone: 4 },
//         //     { code: 'MD', name: 'Moldova, Rep. Of', zone: 4 },
//         //     { code: 'MN', name: 'Mongolia', zone: 4 },
//         //     { code: 'ME', name: 'Montenegro, Rep Of', zone: 4 },
//         //     { code: 'MS', name: 'Montserrat', zone: 4 },
//         //     { code: 'MA', name: 'Morocco', zone: 4 },
//         //     { code: 'MZ', name: 'Mozambique', zone: 4 },
//         //     { code: 'NA', name: 'Namibia', zone: 4 },
//         //     { code: 'NR', name: 'Nauru, Rep. Of', zone: 4 },
//         //     { code: 'XN', name: 'Nevis', zone: 4 },
//         //     { code: 'NC', name: 'New Caledonia', zone: 4 },
//         //     { code: 'NI', name: 'Nicaragua', zone: 4 },
//         //     { code: 'NE', name: 'Niger', zone: 4 },
//         //     { code: 'NG', name: 'Nigeria', zone: 4 },
//         //     { code: 'NU', name: 'Niue', zone: 4 },
//         //     { code: 'MK', name: 'North Macedonia', zone: 4 },
//         //     { code: 'MP', name: 'Northern Mariana Islands', zone: 4 },
//         //     { code: 'PW', name: 'Palau', zone: 4 },
//         //     { code: 'PA', name: 'Panama', zone: 4 },
//         //     { code: 'PG', name: 'Papua New Guinea', zone: 4 },
//         //     { code: 'PY', name: 'Paraguay', zone: 4 },
//         //     { code: 'PE', name: 'Peru', zone: 4 },
//         //     { code: 'PR', name: 'Puerto Rico', zone: 4 },
//         //     { code: 'RE', name: 'Reunion, Island Of', zone: 4 },
//         //     { code: 'RU', name: 'Russian Federation', zone: 4 },
//         //     { code: 'RW', name: 'Rwanda', zone: 4 },
//         //     { code: 'SH', name: 'Saint Helena', zone: 4 },
//         //     { code: 'WS', name: 'Samoa', zone: 4 },
//         //     { code: 'ST', name: 'Sao Tome And Principe', zone: 4 },
//         //     { code: 'SN', name: 'Senegal', zone: 4 },
//         //     { code: 'RS', name: 'Serbia, Rep. Of', zone: 4 },
//         //     { code: 'SC', name: 'Seychelles', zone: 4 },
//         //     { code: 'SL', name: 'Sierra Leone', zone: 4 },
//         //     { code: 'SB', name: 'Solomon Islands', zone: 4 },
//         //     { code: 'SO', name: 'Somalia', zone: 4 },
//         //     { code: 'XS', name: 'Somaliland, Rep Of', zone: 4 },
//         //     { code: 'ZA', name: 'South Africa', zone: 4 },
//         //     { code: 'SS', name: 'South Sudan', zone: 4 },
//         //     { code: 'XY', name: 'St. Barthelemy', zone: 4 },
//         //     { code: 'XE', name: 'St. Eustatius', zone: 4 },
//         //     { code: 'KN', name: 'St. Kitts', zone: 4 },
//         //     { code: 'LC', name: 'St. Lucia', zone: 4 },
//         //     { code: 'XM', name: 'St. Maarten', zone: 4 },
//         //     { code: 'VC', name: 'St. Vincent', zone: 4 },
//         //     { code: 'SD', name: 'Sudan', zone: 4 },
//         //     { code: 'SR', name: 'Suriname', zone: 4 },
//         //     { code: 'SY', name: 'Syria', zone: 4 },
//         //     { code: 'PF', name: 'Tahiti', zone: 4 },
//         //     { code: 'TJ', name: 'Tajikistan', zone: 4 },
//         //     { code: 'TZ', name: 'Tanzania', zone: 4 },
//         //     { code: 'TL', name: 'Timor-Leste', zone: 4 },
//         //     { code: 'TG', name: 'Togo', zone: 4 },
//         //     { code: 'TO', name: 'Tonga', zone: 4 },
//         //     { code: 'TT', name: 'Trinidad And Tobago', zone: 4 },
//         //     { code: 'TN', name: 'Tunisia', zone: 4 },
//         //     { code: 'TM', name: 'Turkmenistan', zone: 4 },
//         //     { code: 'TC', name: 'Turks & Caicos', zone: 4 },
//         //     { code: 'TV', name: 'Tuvalu', zone: 4 },
//         //     { code: 'UG', name: 'Uganda', zone: 4 },
//         //     { code: 'UA', name: 'Ukraine', zone: 4 },
//         //     { code: 'UY', name: 'Uruguay', zone: 4 },
//         //     { code: 'UZ', name: 'Uzbekistan', zone: 4 },
//         //     { code: 'VU', name: 'Vanuatu', zone: 4 },
//         //     { code: 'VE', name: 'Venezuela', zone: 4 },
//         //     { code: 'VG', name: 'Virgin Islands-British', zone: 4 },
//         //     { code: 'VI', name: 'Virgin Islands-US', zone: 4 },
//         //     { code: 'YE', name: 'Yemen, Rep. Of', zone: 4 },
//         //     { code: 'ZM', name: 'Zambia', zone: 4 },
//         //     { code: 'ZW', name: 'Zimbabwe', zone: 4 },
//         // ];

//         // let inputData = [
//         //     {
//         //         "1": "20.00",
//         //         "2": "26.00",
//         //         "3": "32.00",
//         //         "4": "38.00",
//         //         "5": "44.00",
//         //         "6": "50.00",
//         //         "7": "56.00",
//         //         "8": "62.00",
//         //         "9": "68.00",
//         //         "10": "74.00",
//         //         "11": "80.00",
//         //         "12": "86.00",
//         //         "13": "92.00",
//         //         "14": "98.00",
//         //         "15": "104.00",
//         //         "16": "110.00",
//         //         "17": "116.00",
//         //         "18": "122.00",
//         //         "19": "128.00",
//         //         "20": "134.00",
//         //         "21": "137.00",
//         //         "22": "140.00",
//         //         "23": "143.00",
//         //         "24": "146.00",
//         //         "25": "149.00",
//         //         "26": "152.00",
//         //         "27": "155.00",
//         //         "28": "158.00",
//         //         "29": "161.00",
//         //         "30": "164.00",
//         //         "zone": "1",
//         //         "0.5": "17.00",
//         //         "1.5": "23.00",
//         //         "2.5": "29.00",
//         //         "3.5": "35.00",
//         //         "4.5": "41.00",
//         //         "5.5": "47.00",
//         //         "6.5": "53.00",
//         //         "7.5": "59.00",
//         //         "8.5": "65.00",
//         //         "9.5": "71.00",
//         //         "10.5": "77.00",
//         //         "11.5": "83.00",
//         //         "12.5": "89.00",
//         //         "13.5": "95.00",
//         //         "14.5": "101.00",
//         //         "15.5": "107.00",
//         //         "16.5": "113.00",
//         //         "17.5": "119.00",
//         //         "18.5": "125.00",
//         //         "19.5": "131.00"
//         //     },
//         //     {
//         //         "1": "18.01",
//         //         "2": "26.03",
//         //         "3": "34.05",
//         //         "4": "42.07",
//         //         "5": "50.09",
//         //         "6": "58.11",
//         //         "7": "66.13",
//         //         "8": "74.15",
//         //         "9": "82.17",
//         //         "10": "90.19",
//         //         "11": "98.21",
//         //         "12": "106.23",
//         //         "13": "114.25",
//         //         "14": "122.27",
//         //         "15": "130.29",
//         //         "16": "138.31",
//         //         "17": "146.33",
//         //         "18": "154.35",
//         //         "19": "162.37",
//         //         "20": "170.39",
//         //         "21": "174.4",
//         //         "22": "181.4",
//         //         "23": "189.43",
//         //         "24": "197.45",
//         //         "25": "205.47",
//         //         "26": "213.5",
//         //         "27": "221.52",
//         //         "28": "229.55",
//         //         "29": "237.57",
//         //         "30": "245.59",
//         //         "zone": "2",
//         //         "0.5": "14",
//         //         "1.5": "22.02",
//         //         "2.5": "30.04",
//         //         "3.5": "38.06",
//         //         "4.5": "46.08",
//         //         "5.5": "54.1",
//         //         "6.5": "62.12",
//         //         "7.5": "70.14",
//         //         "8.5": "78.16",
//         //         "9.5": "86.18",
//         //         "10.5": "94.2",
//         //         "11.5": "102.22",
//         //         "12.5": "110.24",
//         //         "13.5": "118.26",
//         //         "14.5": "126.28",
//         //         "15.5": "134.3",
//         //         "16.5": "142.32",
//         //         "17.5": "150.34",
//         //         "18.5": "158.36",
//         //         "19.5": "166.38"
//         //     },
//         //     {
//         //         "1": "29",
//         //         "2": "39",
//         //         "3": "49",
//         //         "4": "59",
//         //         "5": "69",
//         //         "6": "79",
//         //         "7": "89",
//         //         "8": "99",
//         //         "9": "109",
//         //         "10": "119",
//         //         "11": "129",
//         //         "12": "139",
//         //         "13": "149",
//         //         "14": "159",
//         //         "15": "169",
//         //         "16": "179",
//         //         "17": "189",
//         //         "18": "199",
//         //         "19": "209",
//         //         "20": "219",
//         //         "21": "230",
//         //         "22": "241",
//         //         "23": "252",
//         //         "24": "263",
//         //         "25": "274",
//         //         "26": "285",
//         //         "27": "296",
//         //         "28": "307",
//         //         "29": "318",
//         //         "30": "329",
//         //         "zone": "3",
//         //         "0.5": "24",
//         //         "1.5": "34",
//         //         "2.5": "44",
//         //         "3.5": "54",
//         //         "4.5": "64",
//         //         "5.5": "74",
//         //         "6.5": "84",
//         //         "7.5": "94",
//         //         "8.5": "104",
//         //         "9.5": "114",
//         //         "10.5": "124",
//         //         "11.5": "134",
//         //         "12.5": "144",
//         //         "13.5": "154",
//         //         "14.5": "164",
//         //         "15.5": "174",
//         //         "16.5": "184",
//         //         "17.5": "194",
//         //         "18.5": "204",
//         //         "19.5": "214"
//         //     },
//         //     {
//         //         "1": "38",
//         //         "2": "48",
//         //         "3": "58",
//         //         "4": "68",
//         //         "5": "78",
//         //         "6": "88",
//         //         "7": "98",
//         //         "8": "108",
//         //         "9": "118",
//         //         "10": "128",
//         //         "11": "138",
//         //         "12": "148",
//         //         "13": "158",
//         //         "14": "168",
//         //         "15": "178",
//         //         "16": "188",
//         //         "17": "198",
//         //         "18": "208",
//         //         "19": "218",
//         //         "20": "228",
//         //         "21": "233",
//         //         "22": "238",
//         //         "23": "243",
//         //         "24": "248",
//         //         "25": "253",
//         //         "26": "258",
//         //         "27": "263",
//         //         "28": "268",
//         //         "29": "273",
//         //         "30": "278",
//         //         "zone": "4",
//         //         "0.5": "33",
//         //         "1.5": "43",
//         //         "2.5": "53",
//         //         "3.5": "63",
//         //         "4.5": "73",
//         //         "5.5": "83",
//         //         "6.5": "93",
//         //         "7.5": "103",
//         //         "8.5": "113",
//         //         "9.5": "123",
//         //         "10.5": "133",
//         //         "11.5": "143",
//         //         "12.5": "153",
//         //         "13.5": "163",
//         //         "14.5": "173",
//         //         "15.5": "183",
//         //         "16.5": "193",
//         //         "17.5": "203",
//         //         "18.5": "213",
//         //         "19.5": "223"
//         //     }
//         // ]

//         // const result = convertDataToZones(inputData);
//         // console.log("result", result);

//         // const result = updateZonesAndFindMissing(countries, newcountries);
//         // console.log("Updated arr1 with zones from arr2:", result.updatedArr1);
//         // console.log("Countries in arr1 not present in arr2:", result.notPresentInArr2);
//         // console.log("Countries in arr2 not present in arr1:", result.notPresentInArr1);



//         // const result = compareCountryArrays(countries, newcountries);
//         // console.log("Differences:", result.differences);
//         // // console.log("Missing Countries:", result.missingCountries);
//         // console.log("Countries missing in arr1:", result.missingInArr1);
//         // console.log("Countries missing in arr2:", result.missingInArr2);



      

//         // let updateedShipment= mergeShipments(colomboexpressdata)
//         // console.log("updateedShipment", updateedShipment)


//         // let colomboexpress = {
//         //     "minWeight": 500,
//         //     "countries": [
//         //         {
//         //             "zone": 1,
//         //             "code": "AU",
//         //             "name": "australia"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "AW",
//         //             "name": "aruba"
//         //         },
//         //         {
//         //             "code": "BH",
//         //             "zone": 2,
//         //             "name": "bahrain"
//         //         },
//         //         {
//         //             "name": "canada",
//         //             "code": "CA",
//         //             "zone": 1
//         //         },
//         //         {
//         //             "name": "china",
//         //             "code": "CN",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "code": "FR",
//         //             "name": "france"
//         //         },
//         //         {
//         //             "code": "DE",
//         //             "zone": 2,
//         //             "name": "germany"
//         //         },
//         //         {
//         //             "code": "HK",
//         //             "zone": 2,
//         //             "name": "hong kong"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "india",
//         //             "code": "IN"
//         //         },
//         //         {
//         //             "name": "italy",
//         //             "zone": 2,
//         //             "code": "IT"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "code": "JP",
//         //             "name": "japan"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "code": "SK",
//         //             "name": "korea south"
//         //         },
//         //         {
//         //             "code": "KW",
//         //             "zone": 2,
//         //             "name": "kuwait"
//         //         },
//         //         {
//         //             "code": "MY",
//         //             "name": "malaysia",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "name": "maldives",
//         //             "code": "MV",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "name": "netherlands",
//         //             "code": "NL",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "name": "newzealand",
//         //             "code": "NZ",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "name": "oman",
//         //             "zone": 2,
//         //             "code": "OM"
//         //         },
//         //         {
//         //             "name": "qatar",
//         //             "code": "QA",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "name": "saudi arabia",
//         //             "zone": 2,
//         //             "code": "SA"
//         //         },
//         //         {
//         //             "name": "singapore",
//         //             "zone": 2,
//         //             "code": "SG"
//         //         },
//         //         {
//         //             "code": "CH",
//         //             "zone": 2,
//         //             "name": "switzerland"
//         //         },
//         //         {
//         //             "code": "AE",
//         //             "zone": 2,
//         //             "name": "United Arab Emirates (UAE)"
//         //         },
//         //         {
//         //             "name": "United Kingdom (UK)",
//         //             "code": "GB",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "name": "United States of America (USA)",
//         //             "code": "US",
//         //             "zone": 1
//         //         },
//         //         {
//         //             "name": "bhutan",
//         //             "code": "BT",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "name": "brunei",
//         //             "code": "BN",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "cambodia",
//         //             "code": "KH"
//         //         },
//         //         {
//         //             "name": "indonesia",
//         //             "zone": 2,
//         //             "code": "ID"
//         //         },
//         //         {
//         //             "code": "IR",
//         //             "zone": 4,
//         //             "name": "iran"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "jordan",
//         //             "code": "JO"
//         //         },
//         //         {
//         //             "code": "LA",
//         //             "zone": 2,
//         //             "name": "laos"
//         //         },
//         //         {
//         //             "code": "MO",
//         //             "name": "macau SAR china",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "code": "MM",
//         //             "name": "myanmar"
//         //         },
//         //         {
//         //             "name": "nepal",
//         //             "zone": 2,
//         //             "code": "NP"
//         //         },
//         //         {
//         //             "code": "PH",
//         //             "zone": 2,
//         //             "name": "philippines"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "code": "TW",
//         //             "name": "taiwan"
//         //         },
//         //         {
//         //             "name": "turkey",
//         //             "zone": 2,
//         //             "code": "TR"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "vietnam",
//         //             "code": "VN"
//         //         },
//         //         {
//         //             "name": "belgium",
//         //             "code": "BE",
//         //             "zone": 1
//         //         },
//         //         {
//         //             "name": "bulgaria",
//         //             "code": "BG",
//         //             "zone": 1
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "cyprus",
//         //             "code": "CY"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "czech republic",
//         //             "code": "CZ"
//         //         },
//         //         {
//         //             "name": "denmark",
//         //             "code": "DK",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "code": "FI",
//         //             "zone": 2,
//         //             "name": "finland"
//         //         },
//         //         {
//         //             "name": "hungary",
//         //             "zone": 2,
//         //             "code": "HU"
//         //         },
//         //         {
//         //             "name": "latvia",
//         //             "zone": 2,
//         //             "code": "LV"
//         //         },
//         //         {
//         //             "code": "LI",
//         //             "name": "liechtenstein",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "code": "LT",
//         //             "zone": 2,
//         //             "name": "lithuania"
//         //         },
//         //         {
//         //             "name": "luxembourg",
//         //             "zone": 2,
//         //             "code": "LU"
//         //         },
//         //         {
//         //             "name": "malta",
//         //             "zone": 2,
//         //             "code": "MT"
//         //         },
//         //         {
//         //             "code": "MX",
//         //             "name": "mexico",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "MC",
//         //             "name": "monaco",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "code": "PL",
//         //             "zone": 2,
//         //             "name": "poland"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "portugal",
//         //             "code": "PT"
//         //         },
//         //         {
//         //             "name": "romania",
//         //             "zone": 2,
//         //             "code": "RO"
//         //         },
//         //         {
//         //             "name": "slovakia",
//         //             "code": "SK",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "name": "slovenia",
//         //             "zone": 2,
//         //             "code": "SI"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "spain",
//         //             "code": "ES"
//         //         },
//         //         {
//         //             "name": "sweden",
//         //             "code": "SE",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "code": "VA",
//         //             "name": "vatican city"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "east timor",
//         //             "code": "TS"
//         //         },
//         //         {
//         //             "name": "fiji",
//         //             "zone": 4,
//         //             "code": "FJ"
//         //         },
//         //         {
//         //             "code": "MK",
//         //             "name": "macedonia",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "NT",
//         //             "zone": 4,
//         //             "name": "netherland antilles"
//         //         },
//         //         {
//         //             "code": "RU",
//         //             "name": "russia",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "rwanda",
//         //             "zone": 4,
//         //             "code": "RW"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "saipan",
//         //             "code": "SP"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "RS",
//         //             "name": "serbia"
//         //         },
//         //         {
//         //             "name": "sierra leone",
//         //             "zone": 4,
//         //             "code": "SL"
//         //         },
//         //         {
//         //             "name": "somaliland",
//         //             "code": "XS",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "ZA",
//         //             "name": "south africa"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "VC",
//         //             "name": "st. vincent"
//         //         },
//         //         {
//         //             "name": "south sudan",
//         //             "code": "SS",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "SZ",
//         //             "name": "swaziland"
//         //         },
//         //         {
//         //             "name": "yugoslovia",
//         //             "zone": 4,
//         //             "code": "YG"
//         //         },
//         //         {
//         //             "name": "zaire",
//         //             "code": "ZR",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "bangladesh",
//         //             "code": "BD"
//         //         },
//         //         {
//         //             "name": "ireland",
//         //             "zone": 2,
//         //             "code": "IE"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "pakistan",
//         //             "code": "PK"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "thailand",
//         //             "code": "TH"
//         //         },
//         //         {
//         //             "name": "Afghanistan ",
//         //             "code": "AF",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "AL",
//         //             "name": "Albania "
//         //         },
//         //         {
//         //             "code": "DZ",
//         //             "zone": 4,
//         //             "name": "Algeria"
//         //         },
//         //         {
//         //             "code": "AS",
//         //             "name": "American Samoa",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Andorra ",
//         //             "zone": 1,
//         //             "code": "AD"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Angola ",
//         //             "code": "AO"
//         //         },
//         //         {
//         //             "code": "AI",
//         //             "zone": 4,
//         //             "name": "Anguilla"
//         //         },
//         //         {
//         //             "name": "Antigua ",
//         //             "code": "AG",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "AR",
//         //             "name": "Argentina "
//         //         },
//         //         {
//         //             "name": "Armenia",
//         //             "code": "AM",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 1,
//         //             "code": "AT",
//         //             "name": "Austria"
//         //         },
//         //         {
//         //             "code": "AZ",
//         //             "name": "Azerbaijan ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Bahamas ",
//         //             "code": "BS",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Barbados ",
//         //             "zone": 4,
//         //             "code": "BB"
//         //         },
//         //         {
//         //             "code": "BY",
//         //             "zone": 4,
//         //             "name": "Belarus "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "BZ",
//         //             "name": "Belize"
//         //         },
//         //         {
//         //             "code": "BJ",
//         //             "zone": 4,
//         //             "name": "Benin"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "BM",
//         //             "name": "Bermuda "
//         //         },
//         //         {
//         //             "name": "Bolivia",
//         //             "code": "BO",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "XB",
//         //             "name": "Bonaire",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "BA",
//         //             "zone": 4,
//         //             "name": "Bosnia & Herzegovina"
//         //         },
//         //         {
//         //             "name": "Botswana",
//         //             "code": "BW",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "BR",
//         //             "name": "Brazil",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "BF",
//         //             "zone": 4,
//         //             "name": "Burkina Faso"
//         //         },
//         //         {
//         //             "name": "Burundi ",
//         //             "code": "BI",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Cameroon",
//         //             "code": "CM"
//         //         },
//         //         {
//         //             "code": "IC",
//         //             "name": "Canary Islands, The ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Cape Verde",
//         //             "zone": 4,
//         //             "code": "CV"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Cayman Islands ",
//         //             "code": "KY"
//         //         },
//         //         {
//         //             "code": "CF",
//         //             "zone": 4,
//         //             "name": "Central African Rep"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "TD",
//         //             "name": "Chad"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "CL",
//         //             "name": "Chile"
//         //         },
//         //         {
//         //             "code": "CO",
//         //             "zone": 4,
//         //             "name": "Colombia"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "KM",
//         //             "name": "Comoros "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Congo ",
//         //             "code": "CG"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "CD",
//         //             "name": "Congo, DPR "
//         //         },
//         //         {
//         //             "code": "CK",
//         //             "name": "Cook Islands",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Costa Rica ",
//         //             "code": "CR",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "CI",
//         //             "name": "Cote D Ivoire",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Croatia",
//         //             "code": "HR",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "code": "CU",
//         //             "name": "Cuba ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "XC",
//         //             "zone": 4,
//         //             "name": "Curacao "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Djibouti ",
//         //             "code": "DJ"
//         //         },
//         //         {
//         //             "name": "Dominica ",
//         //             "code": "DM",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "DO",
//         //             "name": "Dominican Rep. ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Ecuador",
//         //             "code": "EC",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Egypt",
//         //             "code": "EG"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "SV",
//         //             "name": "El Salvador "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "ER",
//         //             "name": "Eritrea "
//         //         },
//         //         {
//         //             "name": "Estonia ",
//         //             "zone": 2,
//         //             "code": "EE"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "SZ",
//         //             "name": "Eswatini "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "ET",
//         //             "name": "Ethiopia "
//         //         },
//         //         {
//         //             "name": "Falkland Islands ",
//         //             "code": "FK",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "FO",
//         //             "name": "Faroe Islands "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "GF",
//         //             "name": "French Guyana "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "GA",
//         //             "name": "Gabon "
//         //         },
//         //         {
//         //             "name": "Gambia ",
//         //             "zone": 4,
//         //             "code": "GM"
//         //         },
//         //         {
//         //             "name": "Georgia ",
//         //             "code": "GE",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "GH",
//         //             "name": "Ghana ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Gibraltar",
//         //             "code": "GI"
//         //         },
//         //         {
//         //             "code": "GR",
//         //             "name": "Greece ",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "GL",
//         //             "name": "Greenland"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Grenada",
//         //             "code": "GD"
//         //         },
//         //         {
//         //             "code": "GP",
//         //             "zone": 4,
//         //             "name": "Guadeloupe "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "GU",
//         //             "name": "Guam "
//         //         },
//         //         {
//         //             "name": "Guatemala",
//         //             "zone": 4,
//         //             "code": "GT"
//         //         },
//         //         {
//         //             "code": "GG",
//         //             "zone": 4,
//         //             "name": "Guernsey"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "GN",
//         //             "name": "Guinea Rep. "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "GW",
//         //             "name": "Guinea-Bissau"
//         //         },
//         //         {
//         //             "code": "GQ",
//         //             "zone": 4,
//         //             "name": "Guinea-Equatorial "
//         //         },
//         //         {
//         //             "code": "GY",
//         //             "name": "Guyana (British) ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Haiti ",
//         //             "code": "HT"
//         //         },
//         //         {
//         //             "code": "HN",
//         //             "name": "Honduras",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Iceland ",
//         //             "code": "IS",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Iraq",
//         //             "code": "IQ"
//         //         },
//         //         {
//         //             "code": "IL",
//         //             "name": "Israel ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "JM",
//         //             "name": "Jamaica ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Jersey",
//         //             "code": "JE",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "KZ",
//         //             "zone": 4,
//         //             "name": "Kazakhstan"
//         //         },
//         //         {
//         //             "code": "KE",
//         //             "name": "Kenya",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Kiribati",
//         //             "code": "KI"
//         //         },
//         //         {
//         //             "name": "Korea,D.P.R Of ",
//         //             "code": "KR",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Kosovo",
//         //             "code": "KV",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "KG",
//         //             "name": "Kyrgyzstan",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "LB",
//         //             "zone": 4,
//         //             "name": "Lebanon"
//         //         },
//         //         {
//         //             "code": "LS",
//         //             "zone": 4,
//         //             "name": "Lesotho "
//         //         },
//         //         {
//         //             "code": "LR",
//         //             "name": "Liberia ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Libya ",
//         //             "code": "LY",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "MG",
//         //             "zone": 4,
//         //             "name": "Madagascar"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Malawi ",
//         //             "code": "MW"
//         //         },
//         //         {
//         //             "name": "Mali",
//         //             "zone": 4,
//         //             "code": "ML"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "MP",
//         //             "name": "Mariana Islands "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "MH",
//         //             "name": "Marshall Islands"
//         //         },
//         //         {
//         //             "name": "Martinique",
//         //             "zone": 4,
//         //             "code": "MQ"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "MR",
//         //             "name": "Mauritania "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Mauritius",
//         //             "code": "MU"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Mayotte",
//         //             "code": "YT"
//         //         },
//         //         {
//         //             "code": "FM",
//         //             "zone": 4,
//         //             "name": "Micronesia "
//         //         },
//         //         {
//         //             "name": "Moldova, Rep. Of ",
//         //             "code": "MD",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Mongolia ",
//         //             "code": "MN",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "ME",
//         //             "name": "Montenegro, Rep Of "
//         //         },
//         //         {
//         //             "name": "Montserrat ",
//         //             "code": "MS",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "MA",
//         //             "name": "Morocco ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Mozambique ",
//         //             "code": "MZ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Namibia ",
//         //             "zone": 4,
//         //             "code": "NA"
//         //         },
//         //         {
//         //             "name": "Nauru, Rep. Of ",
//         //             "code": "NR",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Nevis ",
//         //             "code": "XN"
//         //         },
//         //         {
//         //             "name": "New Caledonia ",
//         //             "code": "NC",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Nicaragua ",
//         //             "zone": 4,
//         //             "code": "NI"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "NE",
//         //             "name": "Niger "
//         //         },
//         //         {
//         //             "name": "Nigeria ",
//         //             "zone": 4,
//         //             "code": "NG"
//         //         },
//         //         {
//         //             "code": "NU",
//         //             "zone": 4,
//         //             "name": "Niue "
//         //         },
//         //         {
//         //             "name": "North Macedonia ",
//         //             "code": "MK",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Norway ",
//         //             "code": "NO",
//         //             "zone": 2
//         //         },
//         //         {
//         //             "name": "Palau ",
//         //             "zone": 4,
//         //             "code": "PW"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Panama ",
//         //             "code": "PA"
//         //         },
//         //         {
//         //             "code": "PG",
//         //             "zone": 4,
//         //             "name": "Papua New Guinea "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Paraguay ",
//         //             "code": "PY"
//         //         },
//         //         {
//         //             "code": "PE",
//         //             "name": "Peru ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "PR",
//         //             "name": "Puerto Rico ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Reunion, Island Of ",
//         //             "code": "RE",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "SH",
//         //             "name": "Saint Helena "
//         //         },
//         //         {
//         //             "name": "Samoa ",
//         //             "zone": 4,
//         //             "code": "WS"
//         //         },
//         //         {
//         //             "zone": 2,
//         //             "name": "San Marino ",
//         //             "code": "SM"
//         //         },
//         //         {
//         //             "code": "ST",
//         //             "zone": 4,
//         //             "name": "Sao Tome And Principe "
//         //         },
//         //         {
//         //             "name": "Senegal ",
//         //             "code": "SN",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Seychelles ",
//         //             "code": "SC",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Solomon Islands ",
//         //             "code": "SB",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "SO",
//         //             "zone": 4,
//         //             "name": "Somalia "
//         //         },
//         //         {
//         //             "name": "St. Barthelemy ",
//         //             "zone": 4,
//         //             "code": "XY"
//         //         },
//         //         {
//         //             "name": "St. Eustatius ",
//         //             "code": "XE",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "KN",
//         //             "zone": 4,
//         //             "name": "St. Kitts "
//         //         },
//         //         {
//         //             "code": "LC",
//         //             "name": "St. Lucia ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "XM",
//         //             "zone": 4,
//         //             "name": "St. Maarten "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "SD",
//         //             "name": "Sudan "
//         //         },
//         //         {
//         //             "code": "SR",
//         //             "zone": 4,
//         //             "name": "Suriname "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "SY",
//         //             "name": "Syria "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "PF",
//         //             "name": "Tahiti "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Tajikistan ",
//         //             "code": "TJ"
//         //         },
//         //         {
//         //             "code": "TZ",
//         //             "name": "Tanzania ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Timor-Leste ",
//         //             "zone": 4,
//         //             "code": "TL"
//         //         },
//         //         {
//         //             "name": "Togo TG",
//         //             "zone": 4,
//         //             "code": "TG"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "TO",
//         //             "name": "Tonga "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Trinidad And Tobago ",
//         //             "code": "TT"
//         //         },
//         //         {
//         //             "code": "TN",
//         //             "zone": 4,
//         //             "name": "Tunisia "
//         //         },
//         //         {
//         //             "code": "TM",
//         //             "name": "Turkmenistan ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "TC",
//         //             "name": "Turks & Caicos ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Tuvalu ",
//         //             "code": "TV"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Uganda ",
//         //             "code": "UG"
//         //         },
//         //         {
//         //             "code": "UA",
//         //             "zone": 4,
//         //             "name": "Ukraine "
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "UY",
//         //             "name": "Uruguay "
//         //         },
//         //         {
//         //             "name": "Uzbekistan ",
//         //             "code": "UZ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "name": "Vanuatu ",
//         //             "code": "VU",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "name": "Venezuela ",
//         //             "code": "VE"
//         //         },
//         //         {
//         //             "name": "Virgin Islands-British ",
//         //             "zone": 4,
//         //             "code": "VG"
//         //         },
//         //         {
//         //             "zone": 4,
//         //             "code": "VI",
//         //             "name": "Virgin Islands-US "
//         //         },
//         //         {
//         //             "name": "Yemen, Rep. Of ",
//         //             "zone": 4,
//         //             "code": "YE"
//         //         },
//         //         {
//         //             "code": "ZM",
//         //             "name": "Zambia ",
//         //             "zone": 4
//         //         },
//         //         {
//         //             "code": "ZW",
//         //             "zone": 4,
//         //             "name": "Zimbabwe "
//         //         }
//         //     ],
//         //     "title": "Colombo Mail Express",
//         //     "addingValue": 500,
//         //     "maxWeight": 30000,
//         //     "order": 1,
//         //     "img": "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2Fcolombo%20mail%20express.jpeg?alt=media&token=87388c15-7321-4891-904e-aa274161bacf",
//         //     "transit": "3 - 5",
//         //     "description": "End to end tracking update.",
//         //     "zones": {
//         //         "zone1": [
//         //             {
//         //                 "weight": 1,
//         //                 "price": 20
//         //             },
//         //             {
//         //                 "weight": 2,
//         //                 "price": 26
//         //             },
//         //             {
//         //                 "weight": 3,
//         //                 "price": 32
//         //             },
//         //             {
//         //                 "weight": 4,
//         //                 "price": 38
//         //             },
//         //             {
//         //                 "weight": 5,
//         //                 "price": 44
//         //             },
//         //             {
//         //                 "weight": 6,
//         //                 "price": 50
//         //             },
//         //             {
//         //                 "weight": 7,
//         //                 "price": 56
//         //             },
//         //             {
//         //                 "weight": 8,
//         //                 "price": 62
//         //             },
//         //             {
//         //                 "weight": 9,
//         //                 "price": 68
//         //             },
//         //             {
//         //                 "weight": 10,
//         //                 "price": 74
//         //             },
//         //             {
//         //                 "weight": 11,
//         //                 "price": 80
//         //             },
//         //             {
//         //                 "weight": 12,
//         //                 "price": 86
//         //             },
//         //             {
//         //                 "weight": 13,
//         //                 "price": 92
//         //             },
//         //             {
//         //                 "weight": 14,
//         //                 "price": 98
//         //             },
//         //             {
//         //                 "weight": 15,
//         //                 "price": 104
//         //             },
//         //             {
//         //                 "weight": 16,
//         //                 "price": 110
//         //             },
//         //             {
//         //                 "weight": 17,
//         //                 "price": 116
//         //             },
//         //             {
//         //                 "weight": 18,
//         //                 "price": 122
//         //             },
//         //             {
//         //                 "weight": 19,
//         //                 "price": 128
//         //             },
//         //             {
//         //                 "weight": 20,
//         //                 "price": 134
//         //             },
//         //             {
//         //                 "weight": 21,
//         //                 "price": 137
//         //             },
//         //             {
//         //                 "weight": 22,
//         //                 "price": 140
//         //             },
//         //             {
//         //                 "weight": 23,
//         //                 "price": 143
//         //             },
//         //             {
//         //                 "weight": 24,
//         //                 "price": 146
//         //             },
//         //             {
//         //                 "weight": 25,
//         //                 "price": 149
//         //             },
//         //             {
//         //                 "weight": 26,
//         //                 "price": 152
//         //             },
//         //             {
//         //                 "weight": 27,
//         //                 "price": 155
//         //             },
//         //             {
//         //                 "weight": 28,
//         //                 "price": 158
//         //             },
//         //             {
//         //                 "weight": 29,
//         //                 "price": 161
//         //             },
//         //             {
//         //                 "weight": 30,
//         //                 "price": 164
//         //             },
//         //             {
//         //                 "weight": 0.5,
//         //                 "price": 17
//         //             },
//         //             {
//         //                 "weight": 1.5,
//         //                 "price": 23
//         //             },
//         //             {
//         //                 "weight": 2.5,
//         //                 "price": 29
//         //             },
//         //             {
//         //                 "weight": 3.5,
//         //                 "price": 35
//         //             },
//         //             {
//         //                 "weight": 4.5,
//         //                 "price": 41
//         //             },
//         //             {
//         //                 "weight": 5.5,
//         //                 "price": 47
//         //             },
//         //             {
//         //                 "weight": 6.5,
//         //                 "price": 53
//         //             },
//         //             {
//         //                 "weight": 7.5,
//         //                 "price": 59
//         //             },
//         //             {
//         //                 "weight": 8.5,
//         //                 "price": 65
//         //             },
//         //             {
//         //                 "weight": 9.5,
//         //                 "price": 71
//         //             },
//         //             {
//         //                 "weight": 10.5,
//         //                 "price": 77
//         //             },
//         //             {
//         //                 "weight": 11.5,
//         //                 "price": 83
//         //             },
//         //             {
//         //                 "weight": 12.5,
//         //                 "price": 89
//         //             },
//         //             {
//         //                 "weight": 13.5,
//         //                 "price": 95
//         //             },
//         //             {
//         //                 "weight": 14.5,
//         //                 "price": 101
//         //             },
//         //             {
//         //                 "weight": 15.5,
//         //                 "price": 107
//         //             },
//         //             {
//         //                 "weight": 16.5,
//         //                 "price": 113
//         //             },
//         //             {
//         //                 "weight": 17.5,
//         //                 "price": 119
//         //             },
//         //             {
//         //                 "weight": 18.5,
//         //                 "price": 125
//         //             },
//         //             {
//         //                 "weight": 19.5,
//         //                 "price": 131
//         //             }
//         //         ],
//         //         "zone2": [
//         //             {
//         //                 "weight": 1,
//         //                 "price": 18.01
//         //             },
//         //             {
//         //                 "weight": 2,
//         //                 "price": 26.03
//         //             },
//         //             {
//         //                 "weight": 3,
//         //                 "price": 34.05
//         //             },
//         //             {
//         //                 "weight": 4,
//         //                 "price": 42.07
//         //             },
//         //             {
//         //                 "weight": 5,
//         //                 "price": 50.09
//         //             },
//         //             {
//         //                 "weight": 6,
//         //                 "price": 58.11
//         //             },
//         //             {
//         //                 "weight": 7,
//         //                 "price": 66.13
//         //             },
//         //             {
//         //                 "weight": 8,
//         //                 "price": 74.15
//         //             },
//         //             {
//         //                 "weight": 9,
//         //                 "price": 82.17
//         //             },
//         //             {
//         //                 "weight": 10,
//         //                 "price": 90.19
//         //             },
//         //             {
//         //                 "weight": 11,
//         //                 "price": 98.21
//         //             },
//         //             {
//         //                 "weight": 12,
//         //                 "price": 106.23
//         //             },
//         //             {
//         //                 "weight": 13,
//         //                 "price": 114.25
//         //             },
//         //             {
//         //                 "weight": 14,
//         //                 "price": 122.27
//         //             },
//         //             {
//         //                 "weight": 15,
//         //                 "price": 130.29
//         //             },
//         //             {
//         //                 "weight": 16,
//         //                 "price": 138.31
//         //             },
//         //             {
//         //                 "weight": 17,
//         //                 "price": 146.33
//         //             },
//         //             {
//         //                 "weight": 18,
//         //                 "price": 154.35
//         //             },
//         //             {
//         //                 "weight": 19,
//         //                 "price": 162.37
//         //             },
//         //             {
//         //                 "weight": 20,
//         //                 "price": 170.39
//         //             },
//         //             {
//         //                 "weight": 21,
//         //                 "price": 174.4
//         //             },
//         //             {
//         //                 "weight": 22,
//         //                 "price": 181.4
//         //             },
//         //             {
//         //                 "weight": 23,
//         //                 "price": 189.43
//         //             },
//         //             {
//         //                 "weight": 24,
//         //                 "price": 197.45
//         //             },
//         //             {
//         //                 "weight": 25,
//         //                 "price": 205.47
//         //             },
//         //             {
//         //                 "weight": 26,
//         //                 "price": 213.5
//         //             },
//         //             {
//         //                 "weight": 27,
//         //                 "price": 221.52
//         //             },
//         //             {
//         //                 "weight": 28,
//         //                 "price": 229.55
//         //             },
//         //             {
//         //                 "weight": 29,
//         //                 "price": 237.57
//         //             },
//         //             {
//         //                 "weight": 30,
//         //                 "price": 245.59
//         //             },
//         //             {
//         //                 "weight": 0.5,
//         //                 "price": 14
//         //             },
//         //             {
//         //                 "weight": 1.5,
//         //                 "price": 22.02
//         //             },
//         //             {
//         //                 "weight": 2.5,
//         //                 "price": 30.04
//         //             },
//         //             {
//         //                 "weight": 3.5,
//         //                 "price": 38.06
//         //             },
//         //             {
//         //                 "weight": 4.5,
//         //                 "price": 46.08
//         //             },
//         //             {
//         //                 "weight": 5.5,
//         //                 "price": 54.1
//         //             },
//         //             {
//         //                 "weight": 6.5,
//         //                 "price": 62.12
//         //             },
//         //             {
//         //                 "weight": 7.5,
//         //                 "price": 70.14
//         //             },
//         //             {
//         //                 "weight": 8.5,
//         //                 "price": 78.16
//         //             },
//         //             {
//         //                 "weight": 9.5,
//         //                 "price": 86.18
//         //             },
//         //             {
//         //                 "weight": 10.5,
//         //                 "price": 94.2
//         //             },
//         //             {
//         //                 "weight": 11.5,
//         //                 "price": 102.22
//         //             },
//         //             {
//         //                 "weight": 12.5,
//         //                 "price": 110.24
//         //             },
//         //             {
//         //                 "weight": 13.5,
//         //                 "price": 118.26
//         //             },
//         //             {
//         //                 "weight": 14.5,
//         //                 "price": 126.28
//         //             },
//         //             {
//         //                 "weight": 15.5,
//         //                 "price": 134.3
//         //             },
//         //             {
//         //                 "weight": 16.5,
//         //                 "price": 142.32
//         //             },
//         //             {
//         //                 "weight": 17.5,
//         //                 "price": 150.34
//         //             },
//         //             {
//         //                 "weight": 18.5,
//         //                 "price": 158.36
//         //             },
//         //             {
//         //                 "weight": 19.5,
//         //                 "price": 166.38
//         //             }
//         //         ],
//         //         "zone3": [
//         //             {
//         //                 "weight": 1,
//         //                 "price": 29
//         //             },
//         //             {
//         //                 "weight": 2,
//         //                 "price": 39
//         //             },
//         //             {
//         //                 "weight": 3,
//         //                 "price": 49
//         //             },
//         //             {
//         //                 "weight": 4,
//         //                 "price": 59
//         //             },
//         //             {
//         //                 "weight": 5,
//         //                 "price": 69
//         //             },
//         //             {
//         //                 "weight": 6,
//         //                 "price": 79
//         //             },
//         //             {
//         //                 "weight": 7,
//         //                 "price": 89
//         //             },
//         //             {
//         //                 "weight": 8,
//         //                 "price": 99
//         //             },
//         //             {
//         //                 "weight": 9,
//         //                 "price": 109
//         //             },
//         //             {
//         //                 "weight": 10,
//         //                 "price": 119
//         //             },
//         //             {
//         //                 "weight": 11,
//         //                 "price": 129
//         //             },
//         //             {
//         //                 "weight": 12,
//         //                 "price": 139
//         //             },
//         //             {
//         //                 "weight": 13,
//         //                 "price": 149
//         //             },
//         //             {
//         //                 "weight": 14,
//         //                 "price": 159
//         //             },
//         //             {
//         //                 "weight": 15,
//         //                 "price": 169
//         //             },
//         //             {
//         //                 "weight": 16,
//         //                 "price": 179
//         //             },
//         //             {
//         //                 "weight": 17,
//         //                 "price": 189
//         //             },
//         //             {
//         //                 "weight": 18,
//         //                 "price": 199
//         //             },
//         //             {
//         //                 "weight": 19,
//         //                 "price": 209
//         //             },
//         //             {
//         //                 "weight": 20,
//         //                 "price": 219
//         //             },
//         //             {
//         //                 "weight": 21,
//         //                 "price": 230
//         //             },
//         //             {
//         //                 "weight": 22,
//         //                 "price": 241
//         //             },
//         //             {
//         //                 "weight": 23,
//         //                 "price": 252
//         //             },
//         //             {
//         //                 "weight": 24,
//         //                 "price": 263
//         //             },
//         //             {
//         //                 "weight": 25,
//         //                 "price": 274
//         //             },
//         //             {
//         //                 "weight": 26,
//         //                 "price": 285
//         //             },
//         //             {
//         //                 "weight": 27,
//         //                 "price": 296
//         //             },
//         //             {
//         //                 "weight": 28,
//         //                 "price": 307
//         //             },
//         //             {
//         //                 "weight": 29,
//         //                 "price": 318
//         //             },
//         //             {
//         //                 "weight": 30,
//         //                 "price": 329
//         //             },
//         //             {
//         //                 "weight": 0.5,
//         //                 "price": 24
//         //             },
//         //             {
//         //                 "weight": 1.5,
//         //                 "price": 34
//         //             },
//         //             {
//         //                 "weight": 2.5,
//         //                 "price": 44
//         //             },
//         //             {
//         //                 "weight": 3.5,
//         //                 "price": 54
//         //             },
//         //             {
//         //                 "weight": 4.5,
//         //                 "price": 64
//         //             },
//         //             {
//         //                 "weight": 5.5,
//         //                 "price": 74
//         //             },
//         //             {
//         //                 "weight": 6.5,
//         //                 "price": 84
//         //             },
//         //             {
//         //                 "weight": 7.5,
//         //                 "price": 94
//         //             },
//         //             {
//         //                 "weight": 8.5,
//         //                 "price": 104
//         //             },
//         //             {
//         //                 "weight": 9.5,
//         //                 "price": 114
//         //             },
//         //             {
//         //                 "weight": 10.5,
//         //                 "price": 124
//         //             },
//         //             {
//         //                 "weight": 11.5,
//         //                 "price": 134
//         //             },
//         //             {
//         //                 "weight": 12.5,
//         //                 "price": 144
//         //             },
//         //             {
//         //                 "weight": 13.5,
//         //                 "price": 154
//         //             },
//         //             {
//         //                 "weight": 14.5,
//         //                 "price": 164
//         //             },
//         //             {
//         //                 "weight": 15.5,
//         //                 "price": 174
//         //             },
//         //             {
//         //                 "weight": 16.5,
//         //                 "price": 184
//         //             },
//         //             {
//         //                 "weight": 17.5,
//         //                 "price": 194
//         //             },
//         //             {
//         //                 "weight": 18.5,
//         //                 "price": 204
//         //             },
//         //             {
//         //                 "weight": 19.5,
//         //                 "price": 214
//         //             }
//         //         ],
//         //         "zone4": [
//         //             {
//         //                 "weight": 1,
//         //                 "price": 38
//         //             },
//         //             {
//         //                 "weight": 2,
//         //                 "price": 48
//         //             },
//         //             {
//         //                 "weight": 3,
//         //                 "price": 58
//         //             },
//         //             {
//         //                 "weight": 4,
//         //                 "price": 68
//         //             },
//         //             {
//         //                 "weight": 5,
//         //                 "price": 78
//         //             },
//         //             {
//         //                 "weight": 6,
//         //                 "price": 88
//         //             },
//         //             {
//         //                 "weight": 7,
//         //                 "price": 98
//         //             },
//         //             {
//         //                 "weight": 8,
//         //                 "price": 108
//         //             },
//         //             {
//         //                 "weight": 9,
//         //                 "price": 118
//         //             },
//         //             {
//         //                 "weight": 10,
//         //                 "price": 128
//         //             },
//         //             {
//         //                 "weight": 11,
//         //                 "price": 138
//         //             },
//         //             {
//         //                 "weight": 12,
//         //                 "price": 148
//         //             },
//         //             {
//         //                 "weight": 13,
//         //                 "price": 158
//         //             },
//         //             {
//         //                 "weight": 14,
//         //                 "price": 168
//         //             },
//         //             {
//         //                 "weight": 15,
//         //                 "price": 178
//         //             },
//         //             {
//         //                 "weight": 16,
//         //                 "price": 188
//         //             },
//         //             {
//         //                 "weight": 17,
//         //                 "price": 198
//         //             },
//         //             {
//         //                 "weight": 18,
//         //                 "price": 208
//         //             },
//         //             {
//         //                 "weight": 19,
//         //                 "price": 218
//         //             },
//         //             {
//         //                 "weight": 20,
//         //                 "price": 228
//         //             },
//         //             {
//         //                 "weight": 21,
//         //                 "price": 233
//         //             },
//         //             {
//         //                 "weight": 22,
//         //                 "price": 238
//         //             },
//         //             {
//         //                 "weight": 23,
//         //                 "price": 243
//         //             },
//         //             {
//         //                 "weight": 24,
//         //                 "price": 248
//         //             },
//         //             {
//         //                 "weight": 25,
//         //                 "price": 253
//         //             },
//         //             {
//         //                 "weight": 26,
//         //                 "price": 258
//         //             },
//         //             {
//         //                 "weight": 27,
//         //                 "price": 263
//         //             },
//         //             {
//         //                 "weight": 28,
//         //                 "price": 268
//         //             },
//         //             {
//         //                 "weight": 29,
//         //                 "price": 273
//         //             },
//         //             {
//         //                 "weight": 30,
//         //                 "price": 278
//         //             },
//         //             {
//         //                 "weight": 0.5,
//         //                 "price": 33
//         //             },
//         //             {
//         //                 "weight": 1.5,
//         //                 "price": 43
//         //             },
//         //             {
//         //                 "weight": 2.5,
//         //                 "price": 53
//         //             },
//         //             {
//         //                 "weight": 3.5,
//         //                 "price": 63
//         //             },
//         //             {
//         //                 "weight": 4.5,
//         //                 "price": 73
//         //             },
//         //             {
//         //                 "weight": 5.5,
//         //                 "price": 83
//         //             },
//         //             {
//         //                 "weight": 6.5,
//         //                 "price": 93
//         //             },
//         //             {
//         //                 "weight": 7.5,
//         //                 "price": 103
//         //             },
//         //             {
//         //                 "weight": 8.5,
//         //                 "price": 113
//         //             },
//         //             {
//         //                 "weight": 9.5,
//         //                 "price": 123
//         //             },
//         //             {
//         //                 "weight": 10.5,
//         //                 "price": 133
//         //             },
//         //             {
//         //                 "weight": 11.5,
//         //                 "price": 143
//         //             },
//         //             {
//         //                 "weight": 12.5,
//         //                 "price": 153
//         //             },
//         //             {
//         //                 "weight": 13.5,
//         //                 "price": 163
//         //             },
//         //             {
//         //                 "weight": 14.5,
//         //                 "price": 173
//         //             },
//         //             {
//         //                 "weight": 15.5,
//         //                 "price": 183
//         //             },
//         //             {
//         //                 "weight": 16.5,
//         //                 "price": 193
//         //             },
//         //             {
//         //                 "weight": 17.5,
//         //                 "price": 203
//         //             },
//         //             {
//         //                 "weight": 18.5,
//         //                 "price": 213
//         //             },
//         //             {
//         //                 "weight": 19.5,
//         //                 "price": 223
//         //             }
//         //         ]
//         //     },
//         //     "name": "colomboMailExpress",
//         //     "queue": 0
//         // }

//     //     db.collection("exportServices").doc("colomboExpress").set(colomboexpress)
//     // .then((res)=>{
//     //     console.log("colomboexpress data addeded",res)
//     // })
//     // .catch((err)=>{
//     //    console.log("Error in adding bulk data",err)
//     // })

//     }).catch((error) => {
//         console.log("error", error)
//     });
    
// }

// function convertDataToZones(data) {
//     const zones = {};

//     data.forEach(entry => {
//         const zoneKey = `zone${entry.zone}`;
//         zones[zoneKey] = [];

//         for (const key in entry) {
//             if (key !== "zone") {
//                 zones[zoneKey].push({
//                     weight: parseFloat(key),
//                     price: parseFloat(entry[key])
//                 });
//             }
//         }
//     });

//     return zones;
// }

function normalizeName(name) {
    return name.replace(/[^a-zA-Z]/g, "").toLowerCase();
}


// function updateZonesAndFindMissing(arr1, arr2) {
//     const notPresentInArr2 = [];
//     const notPresentInArr1 = [];

//     // Create maps for quick lookups based on country codes
//     const arr1Map = new Map(arr1.map(country => [country.code.toLowerCase(), country]));
//     const arr2Map = new Map(arr2.map(country => [country.code.toLowerCase(), country]));

//     // Update zones in arr1 to match arr2 where codes match
//     arr1.forEach(country1 => {
//         const country2 = arr2Map.get(country1.code.toLowerCase());

//         if (country2) {
//             // Update the zone in arr1 to match arr2
//             console.log(`Updating zone for ${country1.name} (Code: ${country1.code}) from Zone ${country1.zone} to Zone ${country2.zone}`);
//             country1.zone = country2.zone;
//         } else {
//             // Country not present in arr2
//             console.log(`%c Country not present in arr2: ${country1.name} (Code: ${country1.code})`, "color: red;");
//             notPresentInArr2.push(country1);
//         }
//     });

//     // Find countries present in arr2 but not in arr1
//     arr2.forEach(country2 => {
//         if (!arr1Map.has(country2.code.toLowerCase())) {
//             console.log(`%c Country not present in arr1: ${country2.name} (Code: ${country2.code})`, "color: blue;");
//             notPresentInArr1.push(country2);
//         }
//     });

//     return {
//         updatedArr1: arr1,
//         notPresentInArr2,
//         notPresentInArr1
//     };
// }

function compareCountryArrays(arr1, arr2) {
    const differences = [];
    const missingInArr1 = []; // Countries missing in arr1 but present in arr2
    const missingInArr2 = []; // Countries missing in arr2 but present in arr1

    const arr2Map = new Map(arr2.map(country => [normalizeName(country.name), country]));

    arr1.forEach(country1 => {
        const normalizedCountryName = normalizeName(country1.name);
        const country2 = arr2Map.get(normalizedCountryName);

        if (country2) {
            // Compare codes and zones
            if (country1.code.toLowerCase() !== country2.code.toLowerCase() || country1.zone !== country2.zone) {
                console.log(
                    `%c Difference in country: ${country1.name} - Code or Zone mismatch`,
                    "color: orange;"
                );
                differences.push({
                    name: country1.name,
                    arr1Code: country1.code,
                    arr2Code: country2.code,
                    arr1Zone: country1.zone,
                    arr2Zone: country2.zone
                });
            }
        } else {
            // Country missing in arr2
            console.log(
                `%c Country missing in second array (arr2): ${country1.name}`,
                "color: red;"
            );
            missingInArr2.push({
                name: country1.name,
                code: country1.code,
                zone: country1.zone
            });
        }
    });

    // Check for any countries in arr2 that are not in arr1
    const arr1Map = new Map(arr1.map(country => [normalizeName(country.name), country]));
    arr2.forEach(country2 => {
        const normalizedCountryName = normalizeName(country2.name);
        if (!arr1Map.has(normalizedCountryName)) {
            console.log(
                `%c Country missing in first array (arr1): ${country2.name}`,
                "color: red;"
            );
            missingInArr1.push({
                name: country2.name,
                code: country2.code,
                zone: country2.zone
            });
        }
    });

    return { differences, missingInArr1, missingInArr2 };
}



// async function mergeShipments(shipment) {
//     let newShipment = []
//     console.log("newshipmett", newShipment)
//     // Start zone counter at 5 (assuming there are zones 1-4 by default)
//     let zoneCounter = Object.keys(shipment.zones).length + 1;

//     // Create a mapping of existing countries in shipment by name (in lowercase for case-insensitive comparison)
//     const countryMapping = shipment.countries.reduce((acc, country) => {
//         acc[country.name.toLowerCase().trim()] = country;
//         return acc;
//     }, {});

//     console.log("countryMapping", countryMapping)

//     // Iterate over newShipment array
//     // Iterate over newShipment array
//     newShipment.forEach(entry => {
//         const countryName = entry.COUNTRY.toLowerCase();
//         console.log("countryName", countryName)
//         // console.log(`%c Country ${entry.Country} is NOT present in shipment data.`, 'background: #222; color: #bada55')
//         // Check if the country already exists in shipment data
//         if (countryMapping[countryName]) {
//             console.log(`Country ${entry.COUNTRY} is present in shipment data.`);

//             const country = countryMapping[countryName];
//             if (country.zone !== zoneCounter.toString()) {
//                 // Log if a country is assigned a new zone
//                 console.log(`Zone changed for country ${country.name} from Zone ${country.zone} to Zone ${zoneCounter}`);
//                 country.zone = zoneCounter; // Update zone for existing country
//             }
//         } else {
//             console.log(`%c Country ${entry.COUNTRY} is NOT present in shipment data.`,'background: #222; color: #bada55');

//             // Add new country to shipment.countries with new zone reference
//             console.log(`New country added: ${entry.COUNTRY} with Zone ${zoneCounter}`);
//             shipment.countries.push({
//                 code: entry.COUNTRY.substring(0, 2).toUpperCase(), // Retain existing country code if any
//                 name: entry.COUNTRY,
//                 zone: zoneCounter.toString()
//             });
//         }

//         // Create new zone with weights and prices from newShipment entry
//         const zoneName = `zone${zoneCounter}`;
//         shipment.zones[zoneName] = Object.keys(entry)
//             .filter(key => key !== 'COUNTRY')
//             .map(weight => ({
//                 weight: parseFloat(weight),
//                 price: parseFloat(entry[weight])
//             }));

//         // Increment zone counter for next unique country
//         zoneCounter++;
//     });

//     console.log("merge shipmet", shipment)

//     return shipment;
// }