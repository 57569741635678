<template>
  <main>
    <div id="contactus">
      <div class="contact">
        <h2><b>Get in touch</b></h2>
        <p class="enquiry">
          <b
            >Have an Enquiry or some feedback for us? fill out the form to
            contact.</b
          >
        </p>
        <div class="address">
          <div>
            <!-- <div class="location"> -->
            <span class="country-logo"
              ><img src="@/assets/static/home/srilanka.svg" alt=""
            /></span>
            <!-- </div> -->
            <p class="location-address">
              <b>SRI LANKA OFFICE</b>
              <br />
              SUPER SAVE LANKA (PVT) LTD.
              <br />
              No.4, COUNCIL AVENUE, (NEAR DEHIWALA MUNICIPAL COUNCIL)
              <br />
              DEHIWALA,
              <br />
              SRILANKA.
              <br />
              <span class="email">info@colombomail.lk</span>
            </p>
            <b class="phone">(+94) 114 896 660</b>
            <b class="phone">(+94) 755 192 192</b>
          </div>
          <div>
            <span class="country-logo"
              ><img src="@/assets/static/home/uk.svg" alt=""
            /></span>
            <p class="location-address">
              <b>UK OFFICE</b>
              <br />
              SUPER SAVE UK (PVT) LTD.
              <br />
              234 NORTHUMBERLAND AVENUE, READING,
              <br />
              RG2 7QA,
              <br />
              UNITED KINGDOM.
              <br />
              <span class="email">uk@colombomail.lk</span>
            </p>
            <b class="phone">(+44) 779 175 3342</b>
            <b class="phone">(+44) 118 958 9871</b>
          </div>
          <div>
            <span class="country-logo"
              ><img src="@/assets/static/home/uae.svg" alt=""
            /></span>
            <p class="location-address">
              <b>UAE OFFICE</b>
              <br />
              SUPER SAVE GENERAL TRADING LLC,
              <br />
              P.O BOX 376549,
              <br />
              DUBAI,
              <br />
              UNITED ARAB EMIRATES.
              <br />
              <span class="email">uae@colombomail.lk</span>
            </p>
            <b class="phone">(+971) 502 402 360</b>
            <b class="phone">(+971) 674 655 97</b>
          </div>

          <div>
            <div class="address-content-wrap">
              <span class="country-logo"
                ><img
                  style="width: 23px"
                  src="@/assets/static/home/india.png"
                  alt=""
              /></span>
              <p class="location-address">
                <b>INDIA OFFICE</b>
                <br />
                SUPER SAVE INDIA (PVT) LTD.
                <br />
                132 KALAYANARAM STREET,
                <br />
                DHARGA ROAD,
                <br />
                ZAMIN PALLAVARAM,
                <br />
                CHENNAI - 600043.
                <br />
                INDIA
                <br />
                <span class="email">india@colombomail.lk</span>
              </p>
            </div>
            <div class="phone-wrap">
              <b class="phone">(+91) 984 047 4896</b>
              <b class="phone">(+91) 444 853 4946</b>
            </div>
          </div>
          <div>
            <span class="country-logo"
              ><img
                style="width: 23px"
                src="@/assets/static/home/malayasia.png"
                alt=""
            /></span>
            <p class="location-address">
              <b>MALAYSIA OFFICE</b>
              <br />
              SUPER SAVE MALAYSIA (PVT) LTD.
              <br />
              SOHO-1- FLOOR 11 NUMBER13 (11/13),
              <br />
              VISTA ALAM SERVICE APARTMENT,
              <br />
              JALAN IKHTISAS, SEKSYEN14, SHAH ALAM,
              <br />
              MALAYSIA
              <br />

              <span class="email">malaysia@colombomail.lk</span>
            </p>
            <b class="phone">(+60) 162 860 465</b>
            <b class="phone">(+60) 169 433 125</b>
          </div>
        </div>

        <h2 class="support">Support</h2>
        <a href="mailto:info@colombomail.lk" class="support-links">
          <p>
            <span><img src="@/assets/static/home/mail.svg" alt="" /></span>
            &nbsp;
            <b>info@colombomail.lk</b>
          </p>
        </a>
        <a href="tel:(+94)114896660" class="support-links">
          <p>
            <span><img src="@/assets/static/home/call.svg" alt="" /></span
            >&nbsp;
            <b class="phone"
              >Hotline: (+94) 755 192 192
            </b>
          </p>
        </a>

        <a href="tel:(+94) 722 173 542" class="support-links">
          <p>
            <span><img src="@/assets/static/home/call.svg" alt="" /></span
            >&nbsp;
            <b class="phone">Suggestions & Complaints : (+94) 722 173 542</b>
          </p>
        </a>
        <a href="tel:(+94) 777 293 703" class="support-links">
          <p>
            <span><img src="@/assets/static/home/call.svg" alt="" /></span
            >&nbsp;
            <b class="phone">Payoneer & Paypal Support : (+94) 777 293 703</b>
          </p>
        </a>
        <a href="tel:(+94) 11273 1380" class="support-links">
          <p>
            <span><img src="@/assets/static/home/call.svg" alt="" /></span
            >&nbsp;
            <b class="phone">Corporate Line - (+94) 11489 6660</b>
          </p>
        </a>
      </div>
      <div class="form">
        <form @submit.prevent="sendToEmail">
          <div class="names">
            <input
              required
              type="text"
              placeholder="First Name"
              class="name-list"
              v-model="fName"
            />
            <input
              type="text"
              required
              placeholder="Last Name"
              class="name-list rounded-lg"
              rounded-lg
              v-model="lName"
            />
          </div>
          <div class="names mt-5">
            <input
              required
              type="number"
              placeholder="Phone number"
              class="name-list rounded-lg"
              v-model="phone"
            />
            <!-- <img src="@/assets/static/home/mail.svg" alt="" class="mail-icon" /> -->
            <input
              type="email"
              placeholder="Email"
              required
              class="name-list rounded-lg"
              v-model="emailId"
            />
          </div>

          <div class="message-box">
            <img src="@/assets/static/home/msg-icon.svg" class="msg" alt="" />
            <v-textarea
              class="msg-box rounded-lg"
              required
              v-model="body"
              placeholder="What do you wanna know ?"
            >
            </v-textarea>
          </div>
          <div class="submit">
            <button class="" rounded-lg type="submit">Get in Touch</button>
          </div>
        </form>
      </div>
    </div>
    <div class="footer">
      <span>Copyright &copy; 2022 colombomail. All rights reserved</span>
    </div>
  </main>
</template>

<script>
import { getInTouchService } from "../../services";
export default {
  name: "contactUs",
  data() {
    return {
      emailId: "",
      fName: "",
      lName: "",
      body: "",
    };
  },
  methods: {
    async sendToEmail() {
      let info = {
        firstName: this.fName,
        lastName: this.lName,
        from: this.emailId,
        phone: this.phone,
        body: this.body,
      };
      await getInTouchService.sendEmail(info);

      alert("Your mail Sent");

      this.fName = null;
      this.lName = null;
      this.emailId = null;
      this.phone = null;
      this.body = null;

      console.log("info", info);
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  pointer-events: none;
}

.location {
  display: flex;
}

#contactus {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  padding: 20px;
  display: grid;
  grid-template-columns: 60% 40%;
  background: linear-gradient(45deg, #edf1f4, white);

  .support-links {
    text-decoration: none;
    color: #414040;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;

    p {
      color: #414040;
      font-weight: 500;
      font-size: 18px;
      padding: 10px;
    }
  }

  .support {
    font-family: "Poppins", sans-serif;
    margin-top: 25px;
  }

  .contact {
    width: 100%;

    padding: 25px;

    h2 {
      font-family: "Poppins", sans-serif;
    }

    .enquiry {
      color: #808080;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 2px;
    }
  }
}
// NOTE address
.address {
  width: 50vw;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  div {
    width: 30%;
    padding: 20px 10px;
    background: rgb(225, 218, 236);
    border-radius: 10px;
    margin: 5px;
    font-size: 12px;
    color: rgb(29, 29, 29);
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .address-content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  .phone-wrap {
    width: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  background: linear-gradient(40deg, #edf1f4, white);
  color: #615c5c;
  padding: 20px;
  font-weight: 400;
}

.form {
  background-color: #292663;
  border-radius: 20px;
  width: 100%;
  max-height: 600px;
  padding: 38px;

  .names {
    width: 100%;
    display: flex;

    .name-list {
      border: none;
      border-radius: 10px;
      outline: none;
      background-color: white;
      width: 50%;
      padding: 12px;
    }

    .name-list:nth-child(1) {
      margin-right: 12px;
    }

    .name-list:nth-child(2) {
      margin-left: 12px;
    }
  }

  .phoneno {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
  }
  /*Media queries*/
  @media (max-width: 910px) {
    #contactus {
      height: auto;
      padding: 12px;
    }
  }
  .files {
    margin-top: 20px;
    border: none;
    border-radius: 10px;
    outline: none;
    background-color: white;
    width: 100%;
    height: auto;
    padding: 5px;
  }
  .email {
    width: 100%;
    height: auto;
    padding: 12px;
    background: white;
    margin-top: 35px;
    margin-bottom: 25px;
    border-radius: 15px;

    display: flex;

    .email-field {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      border-radius: 10px;
      margin-left: 10px;
    }
  }

  .message-box {
    width: 100%;
    height: auto;
    padding: 12px;
    background: white;
    margin-top: 35px;
    margin-bottom: 25px;
    display: flex;
    border-radius: 15px;

    .msg {
      height: 22px;
      padding: 1px;
    }

    .msg-box {
      width: 100%;
      resize: none;
      background: white;
      margin-left: 10px;
      max-height: 220px;
      resize: none;
      border: none;
      border-radius: 10px !important;
      outline: none;
    }
  }

  .submit {
    height: auto;
    padding: 8px;

    button {
      outline: none;
      border: none;
      background: #f4bf00;
      font-family: "Poppins", sans-serif;
      border-radius: 10px;
      padding: 15px;
      color: white;
      float: right;
    }

    button:hover {
      background: darken(#f4bf00, 5%);
    }
  }
}

/*Media queries*/
@media (max-width: 910px) {
  #contactus {
    height: auto;
    padding: 10px !important;
    display: grid;
    grid-template-columns: repeat(1, 100%);
  }
  .form {
    max-height: none;
  }
}

@media (max-width: 710px) {
  .address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 30px;
    div {
      width: 100%;
      max-width: 250px;
      margin: 10px auto;
    }
    .location-address-india {
      margin-top: 5px;
    }
  }
  .footer {
    text-align: center;
  }
}

.support-links {
  font-size: 16px !important;

  p {
    padding: 0 !important;
  }
}

@media (max-width: 514px) {
  .names {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .name-list {
    border: none;
    outline: none;
    background-color: white;
    width: 100% !important;
    height: auto;
    padding: 12px;
  }

  .name-list:nth-child(1) {
    margin-right: 0 !important;
  }

  .name-list:nth-child(2) {
    margin-left: 0 !important;
    margin-top: 35px;
  }

  .form {
    background-color: #292663;
    border-radius: 20px;
    height: auto;
    width: 94% !important;
    margin-left: 3% !important;
    margin-right: 3% !important;
    padding: 30px !important;
  }
}

@media (max-width: 421px) {
  .form {
    background-color: #292663;
    border-radius: 20px;
    height: auto;
    width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding: 30px !important;
  }
}
</style>
