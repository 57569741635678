import {
  calculatorService
} from "../../services";
import {
  exportCalculator
} from "../../helper/exportCalculation.helper";

const state = () => ({
  calcData: null,
  allExportServices: []
});

const getters = {
  calcData: state => state.calcData,
  allExportServices: state => state.allExportServices
};

const actions = {
  async fetchCalculationData({
    commit
  }) {
    return await calculatorService
      .getCalculationData()
      .then(response => {
        // console.log("response", response);
        commit("setCalculationData", response);
        return true;
      })
      .catch(error => {
        console.error("error from firebase", error);
        return false;
      }); 
  },

  async fetchallExportServices({
    commit
  }) {
    return await calculatorService
      .getallExportServices()
      .then(ExportServices => {
        // console.log("ExportServices", ExportServices);
        commit("setAllExportServices", ExportServices);
        return true;
      })
      .catch(error => {
        console.error("error from firebase", error);
        return false;
      });
  },

  async caculateExportPrice({
    state
  }, {
    courierId,
    to,
    weight
  }) {
    // console.log("caculateExportPrice", state, courierId, to, weight);
    let calcData = state.calcData;
    let allExportServices = state.allExportServices;
    
    return await exportCalculator
      .priceCalculator({
        courierId,
        to,
        weight,
        calcData,
        allExportServices
      })
      .then(result => {
        // console.log("result", result);
        return result;
      })
      .catch(error => {
        console.error("error", error);
        return error;
      });
  }
};

const mutations = {
  setCalculationData: (state, calcData) => (state.calcData = calcData),
  setAllExportServices: (state, ExportServices) =>
    (state.allExportServices = ExportServices)
};

export default {
  state,
  getters,
  actions,
  mutations
}