export const exportCalculator = {
  priceCalculator,
  getUpdatedWeight,
  isTransportable
};

async function priceCalculator({
  courierId,
  to,
  weight,
  calcData,
  allExportServices
}) {
  console.log("priceCalculator", courierId);

  //format the services
  const allExportServicesFormatted = allExportServices
  let calcResult = [];
  let processedpostage = [] // for testing purposes

  for (let i = 0; i < 9; i++) { //thinking i<9 is total no of export services
    // console.log("postage number", i);
    const postage = allExportServicesFormatted[i];

    //update weight
    const updatedWeight = getUpdatedWeight(postage, weight)
    // console.log(i, postage, "updatedWeight", updatedWeight);

    //check wether the postage is calculatable
    const transportable = isTransportable(postage, to, updatedWeight)
    // console.log("transportable", transportable);
    
    processedpostage.push(postage.name)
    // console.log("calculated postage", processedpostage)

    if (transportable == true) {
      let priceCalculationData = await getPrice({
        postage,
        to,
        updatedWeight,
        calcData
      });
      // console.log("postage", postage);
      // console.log("priceCalculationData", priceCalculationData);
      calcResult.push(priceCalculationData);
    }
    // console.log("calcResult;;;;;;;;;;;;;", calcResult)
  }

  // console.log("calcResult", calcResult);
  return calcResult;
}

function getUpdatedWeight(postage, totalWeight) {

  const addingValue = parseFloat(postage.addingValue)
  const minWeight = parseFloat(postage.minWeight)

  let updatedWeight;
  if (parseFloat(totalWeight) <= parseFloat(minWeight)) { updatedWeight = minWeight }
  else {
    if (parseFloat(totalWeight) % addingValue == 0)
      updatedWeight = parseFloat(totalWeight)
    else
      updatedWeight = parseFloat(totalWeight) +
        (addingValue - parseFloat(totalWeight % addingValue))
  }
  if (updatedWeight>20000){
    updatedWeight= Math.round(updatedWeight/1000)*1000
  }
  // console.log("getUpdatedWeight", minWeight, addingValue, totalWeight, updatedWeight);
  return updatedWeight
}

function isTransportable(postage, to, updatedWeight) {
  let transportable = false

  //Hermies
  if (updatedWeight <= 1000 && postage.id == "hermies" && to.code == "GB") {
    // console.log("transporatable according to Hermies Rules");
    transportable = true
  }
  //USPS
  else if (updatedWeight <= 2000 && postage.id == "usps" && to.code == "US") {
    // console.log("transporatable according to USPS Rules");
    transportable = true
  }

  //colombo express
  else if (updatedWeight <= 30000 && postage.id == "colomboExpress") {
    // console.log("transporatable according to Colombo Express Rules");
    // transportable = true
    let countries = postage.countries
    // console.log("countries colombo special transpotable", countries)
    let ssp = null;
    if (countries != undefined) {
      ssp = countries.find(item => {
        return item.code == to.code;
      });

    }
    // console.log("ssp colombo special transpotable",ssp)
    if (ssp == null || ssp == undefined || ssp == '') {
      transportable = false

    } else {
      transportable = true

    }

  }

  //colombo economy
  else if (updatedWeight <= 30000 && postage.id == "colomboEconomy" && to.code == "US") {
    // console.log("transporatable according to Colombo Economy");
    transportable = true
  }
  //colombo special-expedite
  else if (updatedWeight <= 30000 && postage.id == "colomboSpecial") {
    console.log("transporatable according to Colombo special Rules");
    let countries = postage.countries
    // console.log("colombo special postage", postage)
    // console.log("colombo special to", to)
    // console.log("colombo special updatedWeight", updatedWeight)
    // console.log("countries colombo special transpotable", countries)
    let ssp = null;
    if (countries != undefined) {
      ssp = countries.find(item => {
        // if(item.code==to.code){
        //   console.log("ssp colombo special transpotable", item.code)

        // }
        return item.code == to.code;
      });

      
    }
    // console.log("ssp colombo special transpotable",ssp)

    if (ssp == null || ssp == undefined || ssp == ''){
      transportable = false
    }else{
      transportable = true
    }
  }

  //colombo bulk
  else if (updatedWeight >= 11000 && updatedWeight <= 100000 && postage.id == "colomboBulk") {
    let countries= postage.countries
    // console.log("countries colombo bulk transpotable", countries)
    // console.log("to country colombo bulk transpotable", to)

    let ssp = null;
    if (countries != undefined) {
      ssp = countries.find(item => {
        return item.code == to.code;
      });
      console.log("ssp colombo bulk transpotable", ssp)

      // if (ssp == null)
      //   ssp = countries.find(item => item.code == "ROW");
    }
    console.log("last ssp colombo bulk transpotable", ssp)

    if(ssp == null || ssp == undefined || ssp == '') { //countries not present in bulk, transpotable is false
      transportable = false

    }else{
      if (ssp.zone <= 4 && updatedWeight > 30000) {
        transportable = false
      }
      else {
        transportable = true
      }
    }
    
      console.log("transportable bulk", transportable)
    

    // console.log("transporatable according to Colombo bulk Rules", updatedWeight);
    
  }

  //singapore smart
  else if (updatedWeight <= 2000 && postage.id == "singaporeSmart") {
    // console.log("transporatable according to Singapore Smart");
    transportable = true
  }
  //singapore simple
  else if (updatedWeight <= 2000 && postage.id == "singaporeSimple") {
    // console.log("transporatable according to Singapore Simple");
    transportable = true
  }
  //swiss fully tracked
  else if (updatedWeight <= 2000 && postage.id == "swissFullyTracked") {
    // console.log("transporatable according to Swiss Fully Tracked");
    transportable = true
  }
  //swiss country tracked
  else if (updatedWeight <= 2000 && postage.id == "swissCountryTrack") {
    // console.log("transporatable according to Swiss Country Tracked");
    transportable = true
  }
  //india post
  else if (updatedWeight <= 2000 && postage.id == "indiaPost") {
    // console.log("transporatable according to India Post");
    transportable = true
  }
  else
    transportable = false

  // console.log("Last transporatable ", transportable);
  return transportable
}

async function getPrice({ postage, to, updatedWeight, calcData }) {

  let priceCalculationData = null;


  const countries = postage.countries
  // console.log("postage name", postage.name, updatedWeight)
  // console.log("to.code", to.code)
  




  let ssp = null;
  if (countries != undefined) {
    ssp = countries.find(item => {
      if (item.code == to.code){

        // console.log("== to.code, item.code ", to.code, item.code)
      }

      return item.code == to.code;
    });
    if (ssp == null)
      ssp = countries.find(item => item.code == "ROW");
  }

  let cc, pp, kgc, fc, com;
  if (ssp != undefined && ssp != null) {
    cc = parseFloat(ssp.covidCost);
    // cc = 100;
    pp = parseFloat(ssp.pieceCost);
    // pp = 100;
    kgc = parseFloat(ssp.kgCost);
    fc = parseFloat(ssp.fuelCost);
    com = parseFloat(ssp.commissionCost);

  }





  //currencies
  const singaporeSwissMalaysianRinggit = parseFloat(calcData.currencies.singaporeSwissMalaysianRinggit);
  const singaporeSwissfuelDollar = parseFloat(calcData.currencies.singaporeSwissfuelDollar);
  const colomboExpress = parseFloat(calcData.currencies.colomboExpress);
  const assendia = parseFloat(calcData.currencies.assendia);
  const hermies = parseFloat(calcData.currencies.hermies);
  const usps = parseFloat(calcData.currencies.usps);
  const indiaPost = parseFloat(calcData.currencies.indiaPost);
  // const colomboBulk = parseFloat(calcData.currencies.colomboBulk); // using same currency cost as colombo express for colombo bulk and special
  // const colomboSpecial = parseFloat(calcData.currencies.colomboSpecial);

  let calculatedCost = null;


  if (postage.id == "singaporeSimple" || postage.id == "singaporeSmart") {

    const perKg = (updatedWeight * (kgc / 1000) * singaporeSwissMalaysianRinggit)
    const perPiece = pp * singaporeSwissMalaysianRinggit
    const coviedCost = (updatedWeight * (cc / 1000) * singaporeSwissMalaysianRinggit)
    const fuelCost = (updatedWeight * ((fc * singaporeSwissfuelDollar) / 1000))
    const commisionCost = com
    calculatedCost =
      perKg
      + perPiece
      + coviedCost
      + fuelCost
      + commisionCost
    calculatedCost = calculatedCost * 1.03
    


    priceCalculationData = {
      name: postage.title,
      transit: postage.transit + " Weeks",
      rate: parseFloat(calculatedCost).toFixed(2),
      description: postage.description,
      img: postage.img,
      id: postage.id,
    }
  }

  else if (postage.id == "swissFullyTracked" || postage.id == "swissCountryTrack") {
    calculatedCost =
      pp * assendia  //per piece 
      + (updatedWeight * (kgc / 1000) * assendia)  //kg cost = updatedWeight * per gram price * conversion
      + (updatedWeight * (cc / 1000) * assendia) //covid cost 
      + (updatedWeight * ((fc * singaporeSwissfuelDollar) / 1000)) // fuel cost
      + com * assendia // comission cost

    priceCalculationData = {
      name: postage.title,
      transit: postage.transit + " Weeks",
      rate: parseFloat(calculatedCost).toFixed(2),
      description: postage.description,
      img: postage.img,
      id: postage.id,

    }
  }

  else if (postage.id == "colomboExpress") {

    const zoneName = "zone" + ssp.zone;
    // console.log("Zone", zoneName)
    const zonePricesData = postage.zones[zoneName]
    // console.log("zonePricesData", zonePricesData)
    let zonePrice;
    zonePricesData.forEach(zp => {
      if (zp.weight == updatedWeight / 1000) {
        zonePrice = zp;
      }
    })
    // console.log("zonePrice", zonePrice)


    const price = zonePrice.price

    calculatedCost = price * colomboExpress

    priceCalculationData = {
      name: postage.title,
      transit: postage.transit + " Working Days",
      rate: parseFloat(calculatedCost).toFixed(2),
      description: postage.description,
      img: postage.img,
      id: postage.id,
    }
  }

  else if (postage.id == "colomboSpecial") {

    const zoneName = "zone" + ssp.zone;
    const zonePricesData = postage.zones[zoneName]
    let zonePrice;
    zonePricesData.forEach(zp => {
      if (zp.weight == updatedWeight / 1000) {
        zonePrice = zp;
      }
    })

    const price = zonePrice.price

    calculatedCost = price * colomboExpress

    priceCalculationData = {
      name: postage.title,
      transit: postage.transit + " Working Days",
      rate: parseFloat(calculatedCost).toFixed(2),
      description: postage.description,
      img: postage.img,
      id: postage.id,
    }
  }

  else if (postage.id == "colomboBulk") {

    // console.log(`%c ssp zone ${ssp.zone} `, 'background: #222; color: #bada55')
    const zoneName = "zone" + ssp.zone;
    // console.log(`%c ssp zone ${zoneName} `, 'background: #222; color: #bada55')
    const zonePricesData = postage.zones[zoneName]
    // console.log("zonepricesdata",zonePricesData)
    // console.log("updatedWeight", updatedWeight)

    let zonePrice;
    zonePricesData.forEach(zp => {
      if (zp.weight == updatedWeight / 1000) {
        zonePrice = zp;
      }
    })
    // console.log("zonePrice", zonePrice)


    const price = zonePrice.price
    // console.log("price", price)

    if (ssp.zone > 4){
      calculatedCost = price
    }else{
      calculatedCost = price * colomboExpress

    }

    priceCalculationData = {
      name: postage.title,
      transit: postage.transit + " Working Days",
      rate: parseFloat(calculatedCost).toFixed(2),
      description: postage.description,
      img: postage.img,
      id: postage.id,
    }
  }

  

  else if (postage.id == "colomboEconomy") {

    const zoneName = "zone" + ssp.zone;
    const zonePricesData = postage.zones[zoneName]
    let zonePrice;
    zonePricesData.forEach(zp => {
      if (zp.weight == updatedWeight / 1000)
        zonePrice = zp;
    })

    const price = zonePrice.price

    calculatedCost = price * colomboExpress

    priceCalculationData = {
      name: postage.title,
      transit: postage.transit + " Working Days",
      rate: parseFloat(calculatedCost).toFixed(2),
      description: postage.description,
      img: postage.img,
      id: postage.id,
    }
  }

  else if (postage.id == "hermies") {
    const priceData = postage.cost
    let calculatedCost;
    priceData.forEach(p => {
      if (p.grams == updatedWeight) {

        // console.log("HERMIES", p.hermiesRate, hermies, p.upsRate);

        calculatedCost = (p.hermiesRate * hermies) + p.upsRate;
      }
    })
    priceCalculationData = {
      name: postage.title,
      transit: postage.transit + " Working Days",
      rate: parseFloat(calculatedCost).toFixed(2),
      description: postage.description,
      img: postage.img,
      id: postage.id,
    }
  }

  else if (postage.id == "usps") {
    const priceData = postage.cost
    let calculatedCost;
    priceData.forEach(p => {
      if (p.grams == updatedWeight)
        calculatedCost = (p.uspsRate * usps) + p.upsRate;
    })
    priceCalculationData = {
      name: postage.title,
      transit: postage.transit + " Working Days",
      rate: parseFloat(calculatedCost).toFixed(2),
      description: postage.description,
      img: postage.img,
      id: postage.id,
    }
  }

  else if (postage.id == "indiaPost") {
    let calculatedCost;

    const priceData = postage.cost


    priceData.forEach(p => {
      if (p.grams == updatedWeight) {

        const price = to.code == "US" ? p.usPrice : p.price
        // console.log("$", p, price, indiaPost, p.lkToIn);
        calculatedCost = (price * indiaPost) + p.lkToIn;
        calculatedCost = calculatedCost + (calculatedCost * 0.05)
      }
    });


    priceCalculationData = {
      name: postage.title,
      transit: postage.transit + " Weeks",
      rate: calculatedCost.toFixed(2),
      description: postage.description,
      img: postage.img,
      id: postage.id,
    }
  }

  return priceCalculationData;
}

